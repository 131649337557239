.container {
  & > * {
    padding: 0 1rem;
  }

  &__wide {
    margin: 0 auto;
  }
}
.bar {
  background: var(--es-color-elsevier-green-light);
  padding-block: var(--es-font-size-64);
  padding-inline: var(--es-font-size-16);
  position: relative;
}

.nav {
  background: rgb(var(--es-color-grey1-rgb));
}

.logo {
  right: 1.5rem;
  position: absolute;
  top: 12.5rem;
  z-index: 2;
}
