& [class~="es-chip"]{
  height: 26px;
  box-sizing: border-box;
  margin-left: 8px;
  padding: 1px 12px;
  display: inline-block;
  background: #007F6A;
  border: 1px solid #29A61B;
  border-radius: 20px;
  color: white;

  & > span {
    line-height: 22px;
    height: 22px;
    font-size: 14px;
    display: block;
    padding: 0;
    border: none;
    margin: 0;
  }
}
