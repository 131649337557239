[class~="MuiFormLabel-root"] {
  color: var(--es-color-grey9);
}
[class~="MuiNativeSelect-root"] {
  font-family: var(--es-font-sans-serif);
  margin: 0 0 0 1rem;

  & select[class~="MuiNativeSelect-select"] {
    font-family: var(--es-font-sans-serif);
    font-size: var(--es-font-size-16);
    height: var(--es-line-height);
    padding: 0 1rem 0 0;
    margin: 0;

     option {
      font-family: var(--es-font-sans-serif);
     }
  }

  & > svg {
    // marginTop: 2,
    position: absolute;
    color: rgb(0, 115, 152);
    width: 1rem;
    height: var(--es-line-height);
    right: 0;
  }
}

div[class~="es-listbox"] {
  display: block;
  position: relative;

  & .es-listbox > label {
    color: var(--es-color-theme1);
    display: block;
    font-size: var(--es-font-size-16);
    left: 0;
    padding: 0;
    position: relative;
    top: 0;
    white-space: nowrap;
    z-index: 2;
  }

  & .es-listbox > .es-listbox-button,
  & .es-listbox > select {
    -webkit-appearance: none;
    background-color: transparent;
    border-color: var(--es-color-grey4);
    border-radius: 0;
    border-style: solid;
    border-width: 0 0 2px;
    display: block;
    color: inherit;
    font-family: inherit;
    font-size: inherit;
    height: calc(2em + 4px);
    padding: 0 1.1em 0 0;
    width: 100%;
    position: relative;
    text-align: left;
    z-index: 1;
  }
  & .es-listbox > .es-listbox-button {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  & .es-listbox > svg {
    bottom: 4px;
    box-sizing: border-box;
    color: var(--es-color-theme1);
    display: block;
    fill: currentColor;
    height: 2em;
    padding: 8px 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    z-index: 1;
  }

  & .es-listbox-button[aria-expanded="true"] {
    background-color: var(--es-color-grey2);
    border-bottom: 2px solid var(--es-color-theme2);

    box-shadow: 0 0 0 16px var(--es-color-grey2), -16px -32px 0 0 var(--es-color-grey2),
      16px -32px 0 0 var(--es-color-grey2);
  }
  & .es-listbox-button[aria-expanded="true"] + svg {
    color: var(--es-color-theme2);
    transform: scaleY(-1);
  }

  & .es-listbox-popup {
    align-content: stretch;
    box-shadow: 0 0 0 1px var(--es-color-grey5);
    border-color: var(--es-color-grey2);
    border-style: solid;
    border-width: 0 16px 16px;
    display: flex;
    margin: 0;
    min-height: 240px;
    padding-top: 76px;
    position: absolute;
    z-index: 2;
  }
  & .es-listbox-list {
    background-color: #fff;
    flex: 1 1 100%;
    list-style: none;
    margin: 0;
    overflow: auto;
    overscroll-behavior: contain;
    padding: 16px 0;
  }
  & .es-listbox-list > li {
    padding: 4px 16px;
  }
  & .es-listbox-list > li:hover {
    background-color: var(--es-color-grey1);
  }
  & .es-listbox-list > [aria-selected="true"] > span {
    border-bottom: 2px solid var(--es-color-theme2);
  }

  /* Tertiary styling */
  & .es-listbox.tertiary > .es-listbox-button,
  & .es-listbox.tertiary > select {
    padding: 0 2.2em 0 0;
  }
  & .es-listbox.tertiary > svg {
    border-color: var(--es-color-grey4);
    border-radius: 0;
    border-style: solid;
    border-width: 2px;
    bottom: 4px;
    display: block;
    fill: currentColor;
    height: 2em;
    padding: 6px;
    pointer-events: none;
    position: absolute;
    right: 0;
    width: 2em;
    z-index: 1;
  }
  & .tertiary > .es-listbox-button[aria-expanded="true"] + svg {
    border-color: var(--es-color-theme2);
    background-color: var(--es-color-theme2);
    color: #fff;
  }
}
