// TODO - ADD BUTTON SIZING CLASSES

a[class~="es-button"],
button[class~="es-button"] {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: unset;
  padding: unset;
  font-weight: 400;

  // !  DEFAULT STYLES
  &[class~="es-button__primary"],
  &[class~="es-button__primary"][class~="disabled"],
  &[class~="es-button__secondary"],
  &[class~="es-button__secondary"][class~="disabled"],
  &[class~="es-button__tertiary"],
  &[class~="es-button__tertiary"][class~="disabled"],
  &[class~="es-button__tertiary--02"],
  &[class~="es-button__tertiary--02"][class~="disabled"],
  &[class~="es-button__icon"],
  &[class~="es-button__icon"][class~="disabled"] {
    background-color: var(--es-color--white);
    border-style: solid;
    border: var(--es-fs--01) solid var(--es-color--blue-900);
    border-radius: var(--es-br--xl);
    font-size: var(--es-fs--16);
    line-height: var(--es-lh-16);
    padding-block: var(--es-mp--12);
    padding-inline: var(--es-mp--16);

    &:disabled {
      background-color: var(--es-color--grey-600);
      border-color: var(--es-color--grey-600);
      color: var(--es-color--white);
      cursor: not-allowed;
    }

    // ! THIS IS THE GLOBAL OUTLINE
    // * STILL NEED TO SET UP focus-within IN EACH CLASS(ES) TO CHANGE BACKGROUND-COLOR, BORDER COLOR ETC.
    &:focus-visible {
      outline: 2px solid var(--es-color--blue-900);
      outline-offset: 1px;
      border-color: var(--es-color--white);
    }
  }

  // ! PRIMARY BUTTON STYLE
  &[class~="es-button__primary"] {
    background-color: var(--es-color--blue-900);
    color: var(--es-fc--inverse);

    // ! ANCHOR LINK USING ev-button__primary CLASS DISABLE STYLE
    &[class~="disabled"] {
      background-color: var(--es-color--grey-600);
      border-color: var(--es-color--grey-600);
      color: var(--es-color--white);
    }

    &:hover:not(:disabled) {
      background-color: var(--es-color--blue-1000);
      color: var(--es-color--white);
    }
  }

  // ! SECONDARY, TERTIARY, TERTIARY 02 AND ICON ONLY STYLES
  &[class~="es-button__secondary"],
  &[class~="es-button__secondary"][class~="disabled"],
  &[class~="es-button__tertiary"],
  &[class~="es-button__tertiary"][class~="disabled"],
  &[class~="es-button__tertiary--02"],
  &[class~="es-button__tertiary--02"][class~="disabled"],
  &[class~="es-button__icon"],
  &[class~="es-button__icon"][class~="disabled"] {
    color: var(--es-color--blue-900);

    &:hover:not(:disabled),
    &:focus-visible {
      background-color: var(--es-color--grey-200);
      border-color: var(--es-color--blue-1000);
      color: var(--es-color--blue-1000);
    }
  }

  // ! STYLING ANCHOR LINKS THAT USE THE ev-button__## CLASS
  &[class~="es-button__secondary"][class~="disabled"],
  &[class~="es-button__tertiary"][class~="disabled"],
  &[class~="es-button__tertiary--02"][class~="disabled"],
  &[class~="es-button__icon"][class~="disabled"] {
    border-color: var(--es-color--grey-600);
    color: var(--es-color--grey-600);
    fill: var(--es-color--grey-600);
  }

  // ! BUTTON AND ANCHOR LINKS
  &[class~="es-button__tertiary"],
  &[class~="es-button__tertiary"][class~="disabled"] {
    border-radius: var(--es-br--m);
    border-width: var(--es-fs--02);
    padding-block: var(--es-mp--06);
    padding-inline: var(--es-mp--10);
  }

  &[class~="es-button__tertiary--02"],
  &[class~="es-button__tertiary--02"][class~="disabled"] {
    border-radius: var(--es-br--xl);
    padding-block: var(--es-mp--06);
    padding-inline: var(--es-mp--10);
    background-color: white;
    border-width: var(--es-fs--02);
    border-color: var(--es-color--grey-400);
    font-size: var(--es-fs--14);
    line-height: var(--es-fs--20);

    &:focus-visible {
      border-color: var(--es-color--grey-400);
    }

    &:disabled {
      background-color: var(--es-color--white);
      border-color: var(--es-color--grey-500);
      color: var(--es-color--grey-600);
      cursor: not-allowed;
      svg * {
        fill: var(--es-color--grey-600);
      }
    }
  }

  // ! ICON WITHIN BUTTON
  &[class~="es-button__icon"],
  &[class~="es-button__icon--primary"] {
    border-style: solid;
    border: var(--es-fs--01) solid var(--es-color--blue-900);
    border-width: var(--es-fs--01);
    border-radius: var(--es-br--m);
  }
  &[class~="es-button__icon"] {
    border-color: var(--es-color--grey-700);
    color: var(--es-color--blue-900);
    fill: var(--es-color--blue-900);
    > svg {
      &:hover {
        color: var(--es-color--blue-1000);
      }
    }
  }
  //! PRIMARY BUTTON ICON ONLY
  &[class~="es-button__icon--primary"] {
    background-color: var(--es-color--blue-900);
    color: var(--es-fc--inverse);

    // ! ANCHOR LINK USING ev-button__icon--primary CLASS DISABLE STYLE
    &[class~="disabled"],
    &:disabled {
      background-color: var(--es-color--grey-600);
      border-color: var(--es-color--grey-600);
      color: var(--es-color--white);
    }

    &:hover:not(:disabled) {
      background-color: var(--es-color--blue-1000);
      color: var(--es-color--white);
    }
  }

  // ! SPAN INSIDE ICON BUTTONS SETTING THE ICON TO THE LEFT OR RIGHT
  [class~="es-button__icon--left"],
  [class~="es-button__icon--right"] {
    display: inline-flex;
    align-items: center;
  }
  &[class~="es-button__icon--left"] > svg {
    margin-right: var(--es-mp--04);
  }
  &[class~="es-button__icon--right"] > svg {
    margin-left: var(--es-mp--04);
  }

  // ! THIS IS AN OLDER CLASS NAME
  // TODO - ADD GENERIC ICON ONLY CLASS NAME
  &[class~="es-button__mail-icon"],
  &[class~="es-button__no-border-icon"] {
    text-decoration: none;
    margin: 0;
    color: var(--es-color--black);
    > svg {
      &:hover {
        background-color: transparent;
        color: var(--es-color--blue-1000);
      }
    }
    &[class~="disabled"] {
      background-color: var(--es-color--white);
      border-color: var(--es-color--grey-600);
      color: var(--es-color--grey-600);
      opacity: 0.5;
      cursor: not-allowed;
    }
    &:focus-visible {
      outline: 2px solid var(--es-color--blue-900);
      outline-offset: 1px;
      border-radius: var(--es-font-size-8);
    }
  }

  // ? WHAT IS THIS HERE - DELETE?
  strong,
  bold {
    font-weight: 700;
  }
}
