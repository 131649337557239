$height: 48px;

.search[class~="ev-search-input-container"] {
  position: relative;
  display: block;
  width: 100%;
  &,
  & * {
    box-sizing: border-box;
  }
  > span {
    display: block;
    height: 24px;
    line-height: 24px;
    margin-bottom: 4px;
    > span {
      color: var(--es-color-asterisk-red);
      vertical-align: middle;
      padding: 0 5px;
      position: relative;
      top: -4px;
    }
  }
  > div {
    display: inline;
  }
  input {
    background-color: white;
    border: 1px solid #566780;
    border-radius: 8px;
    appearance: none;
    width: 100%;
    padding: 0 $height 0 1rem;
    font-weight: 400 !important;
    color: #566780 !important;
    height: $height;
    line-height: $height;
    transition: all 100ms;
    outline: unset !important;
    font-size: var(--es-font-size-16);

    &::placeholder {
      color: #566780 !important;
      opacity: 1 !important;
    }

    &:hover {
      border: 2px solid #566780;
    }
    &:focus-visible {
      border-width: 2px;
      color: black !important;
      border-color: black;
    }
  }
  > button {
    position: absolute;
    right: 5px;
    bottom: 5px;
    width: $height - 10;
    height: $height - 10;

    svg {
      height: 1.2rem;
    }
  }
}
