/* The red, green and blue colors are specified as separate values.
 *
 * The advantence of the separate values is that you can use them with
 * an alpha by use the following:
 *
 * color rgba(var(--es-color-warning-rgb), 0.5);
 *
 * Also all the colors are defined already without the rgb and thereby
 * making the following two lines the same.
 *
 * color: rgb(var(--es-color-warning-rgb));
 * color: var(--es-color-warning);
 *
 */

:root {
  /* Gray colors */
  --es-color-grey0-rgb: 255, 255, 255; /* #FFFFFF */
  --es-color-grey1-rgb: 245, 245, 245; /* #F5F5F5 */
  --es-color-grey2-rgb: 235, 235, 235; /* #EBEBEB */
  --es-color-grey3-rgb: 220, 220, 220; /* #DCDCDC */
  --es-color-grey4-rgb: 185, 185, 185; /* #B9B9B9 */
  --es-color-grey5-rgb: 142, 142, 142; /* #8E8E8E */
  --es-color-grey6-rgb: 115, 115, 115; /* #737373 */
  --es-color-grey7-rgb: 80, 80, 80; /* #505050 */
  --es-color-grey8-rgb: 46, 46, 46; /* #2E2E2E */
  --es-color-grey9-rgb: 0, 0, 0; /* #000000 */
  --es-color-grey10-rgb: 223, 225, 236; /* #DFE1EC */

  /* Brand colors */
  --es-color-elsevier-non-accessible-orange-rgb: 233, 113, 28; /* #E9711C */
  --es-color-elsevier-new-orange-rgb: 255 66 3; /* FF4203 */
  --es-color-elsevier-orange-rgb: 235, 101, 0; /* #EB6500 */
  --es-color-elsevier-blue-rgb: 0, 115, 152; /* #007398 */
  --es-color-link-blue-rgb: 12, 125, 187; /* #0C7DBB */
  --es-color-elsevier-orange-ondark-rgb: 255, 130, 0; /* #FF8200 */
  --es-color-elsevier-blue-ondark-rgb: 68, 198, 244; /* #44C6F4 */
  --es-color-elsevier-green-rgb: 1, 127, 106; /* #017F6A */
  --es-color-elsevier-green-light-rgb: 233, 249, 238; /* #E9F9EE */

  /* Messaging colors */
  --es-color-confirmation-rgb: 41, 166, 27; /* #29A61B */
  --es-color-information-rgb: 0, 158, 206; /* #009ECE */
  --es-color-warning-rgb: 200, 55, 39; /* #C83727 */
  --es-color-confirmation-ondark-rgb: 83, 184, 72; /* #53B848 */
  --es-color-information-ondark-rgb: 68, 198, 244; /* #44C6F4 */
  --es-color-warning-ondark-rgb: 255, 106, 90; /* #FF6A5A */

  /* Color swatches */
  --es-color-base-dark-grey-rgb: 83, 86, 90; /* #53565A */
  --es-color-base-white-rgb: 255, 255, 255; /* #FFFFFF */
  --es-color-primary-light-blue-rgb: 54, 121, 224; /* #3679E0 */
  --es-color-primary-non-accessible-orange-rgb: 255, 108, 0; /* #FF6C00 */
  --es-color-primary-orange-rgb: 235, 101, 0; /* #EB6500 */
  --es-color-primary-elsevier-blue-rgb: 0, 115, 152; /* #007398 */
  --es-color-secondary-cool-grey-1-rgb: 220, 220, 221; /* #DCDCDD */
  --es-color-secondary-very-pale-blue-rgb: 205, 228, 255; /* #CDE4FF */
  --es-color-secondary-very-pale-orange-rgb: 255, 240, 228; /* #FFF0E4 */
  --es-color-tertiary-blue1-rgb: 172, 210, 255; /* #ACD2FF */
  --es-color-tertiary-blue2-rgb: 54, 121, 224; /* #3679E0 */
  --es-color-tertiary-blue3-rgb: 7, 57, 115; /* #073973 */
  --es-color-tertiary-green1-rgb: 192, 242, 93; /* #C0F25D */
  --es-color-tertiary-green2-rgb: 142, 215, 0; /* #8ED700 */
  --es-color-tertiary-green3-rgb: 73, 110, 1; /* #496E01 */
  --es-color-tertiary-purple1-rgb: 187, 132, 255; /* #BB84FF */
  --es-color-tertiary-purple2-rgb: 102, 28, 202; /* #661CCA */
  --es-color-tertiary-purple3-rgb: 60, 18, 118; /* #3C1276 */
  --es-color-tertiary-red1-rgb: 254, 183, 183; /* #FEB7B7 */
  --es-color-tertiary-red2-rgb: 247, 62, 41; /* #F73E29 */
  --es-color-tertiary-red3-rgb: 169, 43, 29; /* #A92B1D */
  --es-color-tertiary-yellow1-rgb: 255, 236, 132; /* #FFEC84 */
  --es-color-tertiary-yellow2-rgb: 253, 211, 0; /* #FDD300 */
  --es-color-tertiary-yellow3-rgb: 151, 101, 0; /* #976500 */
  --es-color-a11y-orange-rgb: 235, 101, 0; /* #EB6500 */

  /* New Start Page Colors */
  --sp-color-blue0-rgb: 0, 86, 214; /* #0056d6 */
  --sp-color-blue1-rgb: 0, 70, 173; /* #0046ad */
  --sp-color-blue2-rgb: 86, 103, 128; /* #566780 */
  --sp-color-blue3-rgb: 0, 53, 133; /* #003585 */

  --sp-color-grey0-rgb: 243, 244, 247; /* #f3f4f7 */
  --sp-color-grey1-rgb: 224, 228, 235; /* #e0e4eb */

  --sp-color-red0-rgb: 200, 55, 39; /* #c83727 */

  --sp-color-green0-rgb: 11, 122, 43; /* #0b7a2b */
  --es-color-asterisk-red-rgb: 188, 26, 35; /* BC1A23 */

  /* Gray colors */
  --es-color-grey0: rgb(var(--es-color-grey0-rgb));
  --es-color-grey1: rgb(var(--es-color-grey1-rgb));
  --es-color-grey2: rgb(var(--es-color-grey2-rgb));
  --es-color-grey3: rgb(var(--es-color-grey3-rgb));
  --es-color-grey4: rgb(var(--es-color-grey4-rgb));
  --es-color-grey5: rgb(var(--es-color-grey5-rgb));
  --es-color-grey6: rgb(var(--es-color-grey6-rgb));
  --es-color-grey7: rgb(var(--es-color-grey7-rgb));
  --es-color-grey8: rgb(var(--es-color-grey8-rgb));
  --es-color-grey9: rgb(var(--es-color-grey9-rgb));
  --es-color-grey10: rgb(var(--es-color-grey10-rgb));

  /* Brand colors */
  --es-color-elsevier-orange: rgb(var(--es-color-elsevier-orange-rgb));
  --es-color-elsevier-new-orange: rgb(var(--es-color-elsevier-new-orange-rgb));
  --es-color-elsevier-blue: rgb(var(--es-color-elsevier-blue-rgb));
  --es-color-elsevier-orange-ondark: rgb(var(--es-color-elsevier-orange-ondark-rgb));
  --es-color-elsevier-blue-ondark: rgb(var(--es-color-elsevier-blue-ondark-rgb));
  --es-color-elsevier-green: rgb(var(--es-color-elsevier-green-rgb));
  --es-color-elsevier-green-light: rgb(var(--es-color-elsevier-green-light-rgb));

  /* Messaging colors */
  --es-color-confirmation: rgb(var(--es-color-confirmation-rgb));
  --es-color-information: rgb(var(--es-color-information-rgb));
  --es-color-warning: rgb(var(--es-color-warning-rgb));
  --es-color-confirmation-ondark: rgb(var(--es-color-confirmation-ondark-rgb));
  --es-color-information-ondark: rgb(var(--es-color-information-ondark-rgb));
  --es-color-warning-ondark: rgb(var(--es-color-warning-ondark-rgb));

  /* Color swatches */
  --es-color-base-dark-grey: rgb(var(--es-color-base-dark-grey-rgb));
  --es-color-base-white: rgb(var(--es-color-base-white-rgb));
  --es-color-primary-light-blue: rgb(var(--es-color-primary-light-blue-rgb));
  --es-color-primary-orange: rgb(var(--es-color-primary-orange-rgb));
  --es-color-primary-elsevier-blue: rgb(var(--es-color-primary-elsevier-blue-rgb));
  --es-color-secondary-cool-grey-1: rgb(var(--es-color-secondary-cool-grey-1-rgb));
  --es-color-secondary-very-pale-blue: rgb(var(--es-color-secondary-very-pale-blue-rgb));
  --es-color-secondary-very-pale-orange: rgb(var(--es-color-secondary-very-pale-orange-rgb));
  --es-color-tertiary-blue1: rgb(var(--es-color-tertiary-blue1-rgb));
  --es-color-tertiary-blue2: rgb(var(--es-color-tertiary-blue2-rgb));
  --es-color-tertiary-blue3: rgb(var(--es-color-tertiary-blue3-rgb));
  --es-color-tertiary-green1: rgb(var(--es-color-tertiary-green1-rgb));
  --es-color-tertiary-green2: rgb(var(--es-color-tertiary-green2-rgb));
  --es-color-tertiary-green3: rgb(var(--es-color-tertiary-green3-rgb));
  --es-color-tertiary-purple1: rgb(var(--es-color-tertiary-purple1-rgb));
  --es-color-tertiary-purple2: rgb(var(--es-color-tertiary-purple2-rgb));
  --es-color-tertiary-purple3: rgb(var(--es-color-tertiary-purple3-rgb));
  --es-color-tertiary-red1: rgb(var(--es-color-tertiary-red1-rgb));
  --es-color-tertiary-red2: rgb(var(--es-color-tertiary-red2-rgb));
  --es-color-tertiary-red3: rgb(var(--es-color-tertiary-red3-rgb));
  --es-color-tertiary-yellow1: rgb(var(--es-color-tertiary-yellow1-rgb));
  --es-color-tertiary-yellow2: rgb(var(--es-color-tertiary-yellow2-rgb));
  --es-color-tertiary-yellow3: rgb(var(--es-color-tertiary-yellow3-rgb));

  /* Accessible colors */
  --es-color-a11y-orange: rgb(var(--es-color-a11y-orange-rgb));
  --es-color-link-blue: rgb(var(--es-color-link-blue-rgb));
  --es-color-asterisk-red: rgb(var(--es-color-asterisk-red-rgb));

  /* Link theme color to the specific color values */
  --es-color-background-rgb: var(--es-color-grey0-rgb);
  --es-color-border-rgb: var(--es-color-grey6-rgb);
  --es-color-disabled-rgb: var(--es-color-grey4-rgb);
  --es-color-error-rgb: var(--es-color-warning-rgb);
  --es-color-focus-rgb: var(--es-color-a11y-orange-rgb);
  --es-color-foreground-rgb: var(--es-color-grey8-rgb);
  --es-color-link-rgb: var(--es-color-link-blue-rgb);
  --es-color-theme1-rgb: var(--es-color-elsevier-blue-rgb);
  --es-color-theme2-rgb: var(--es-color-a11y-orange-rgb);

  /* Theme colors */
  --es-color-background: rgb(var(--es-color-background-rgb));
  --es-color-border: rgb(var(--es-color-border-rgb));
  --es-color-disabled: rgb(var(--es-color-disabled-rgb));
  --es-color-error: rgb(var(--es-color-error-rgb));
  --es-color-focus: rgb(var(--es-color-focus-rgb));
  --es-color-foreground: rgb(var(--es-color-foreground-rgb));
  --es-color-link: rgb(var(--es-color-link-rgb));
  --es-color-theme1: rgb(var(--es-color-theme1-rgb));
  --es-color-theme2: rgb(var(--es-color-theme2-rgb));
}
