& [class ~= "MuiBadge-root"] {

    & [class ~="MuiBadge-badge"] {
        background-color: var(--es-color-elsevier-blue-ondark);
        color: var(--es-color-grey9);
        padding: 0;
        font-size: 0.75rem;
        top: -4px;
        right: -4px;
    }
}

& [class ~= "pendo-resource-center-badge-notification-bubble"] {
    display: none;
    font-size: 0.75rem;
    top: -14px !important;
    right: 0 !important;
    text-align: center;

}