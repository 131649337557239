$height: 48px;

.deleteButton {
  min-width: $height;
  max-width: $height;
  height: $height;
  margin: 28px 0 0 0 !important;
  padding: 3px 0 0 0 !important;

  & > svg {
    width: 12px;
    padding: 0;
  }
}
