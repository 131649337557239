.Tabs {
  &__header {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
  }

  &__item {
    padding: 0.8rem;
    display: flex;
    justify-content: center;
    cursor: pointer;
    transition: border-bottom-color 0.2s;
    flex: auto;
    border: none;
    background: none;
    font-size: var(--es-font-size-16);
    font-weight: normal;
    font-family: inherit;
    border-bottom: 3px solid rgb(var(--es-color-grey2-rgb));

    &:hover:not(:disabled) {
      border-bottom-color: rgb(var(--es-color-elsevier-orange-rgb));
    }

    &:disabled {
      cursor: not-allowed;
    }

    &[aria-selected] {
      border-bottom-color: rgb(var(--es-color-elsevier-orange-rgb));
    }
  }

  &__body {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: flex-start;
    padding: 1rem 0;
  }

  &__label {
    padding: 0 4px;
    margin: 2px 0 0 -4px;
    line-height: 20px;
    height: 20px;
  }
}

.selectAllAvailableDBContainer {
  position: sticky;
  top: 57px;
  background-color: white;
  z-index: 99;
}

.selectAllAvailableDB {
  line-height: 24px;
}

.allDBDividerContainer {
  margin-top: 23px;

  &__allDBDivider {
    border-color: rgb(var(--sp-color-grey1-rgb)) !important;
    margin-right: 7px;
    margin-bottom: 25px;
  }
}

.databaseTitleToggleContainer {
  margin-bottom: 17px;

  &__databaseTitle {
    line-height: 24px;
  }

  &__noDBError {
    color: rgb(var(--sp-color-red0-rgb)) !important;
    line-height: 20px;
    margin-top: 8px;
  }
}

.databaseDescription {
  margin-top: -9px;
  margin-bottom: 16px;
  margin-right: 8px;
  line-height: 24px;
}

.bottomdivider {
  border-color: rgb(var(--sp-color-grey1-rgb)) !important;
  margin-right: 7px;
  padding-top: 0;
  margin-top: 8px;
  margin-bottom: 6px;
}

.moreDatabaseContainer {
  margin-top: -10px;
  line-height: 24px;

  &__accordionSummary {
    margin-bottom: 4px;
    margin-right: 16px;
  }

  &__moreDatabase {
    margin-top: 12px;
  }

  &__accordionDetails {
    padding-left: 0px !important;
    margin-bottom: 12px;
    margin-right: 36px;
  }
}

.exploreButtonsContainer {
  display: flex;
  justify-content: flex-end;
  margin-top: var(--es-mp--08);
  margin-right: calc(var(--es-mp--05) * -1);
  margin-bottom: var(--es-mp--18);
  gap: var(--es-mp--16);

  &__contactButton[class~="es-button"][class~="MuiButton-outlinedPrimary"][class~="MuiButton-outlined"] {
    margin-right: var(--es-mp--12);
  }
}

.stickybottom {
  position: sticky;
  bottom: 0;
  align-self: flex-end;
  background: #fff;

  &__applyCancelButtonsContainer {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
    margin-right: -5px;
    gap: 4px;

    &__cancelButton {
      margin-bottom: 24px !important;
      color: rgb(var(--sp-color-blue0-rgb)) !important;
      border-radius: 8px !important;
      border-color: rgb(var(--sp-color-blue0-rgb)) !important;
      line-height: 24px;
      padding-left: 16px !important;
      padding-right: 16px !important;
      padding-top: 12px !important;
      padding-bottom: 12px !important;
      min-width: auto !important;

      &:hover,
      &:focus {
        color: rgb(var(--sp-color-blue1-rgb)) !important;
        background-color: rgb(var(--sp-color-grey0-rgb)) !important;
      }
    }

    &__applyButton {
      margin-bottom: 24px !important;
      color: white !important;
      border-radius: 8px !important;
      background-color: rgb(var(--sp-color-blue0-rgb)) !important;
      border-color: rgb(var(--sp-color-blue0-rgb)) !important;
      line-height: 24px;
      padding-left: 16px !important;
      padding-right: 16px !important;
      padding-top: 12px !important;
      padding-bottom: 12px !important;
      min-width: auto !important;

      &:hover,
      &:focus {
        background-color: rgb(var(--sp-color-blue1-rgb)) !important;
        border-color: rgb(var(--sp-color-blue1-rgb)) !important;
      }
    }
  }
}

.drawer {
  margin-left: 24px;
  margin-right: 17px;

  &__dbContainer {
    margin-bottom: 25px;
    position: sticky;
    background-color: white;
    top: 0;
    z-index: 100;

    &__allDBtitle {
      font-size: 24px;
      font-weight: 300;
      line-height: 32px;
      margin-top: -8px;
      margin-bottom: -8px;
    }

    &__closeButton {
      color: #000000;
      margin-top: 12px;
      margin-left: -2px;

      &:hover,
      &:focus {
        color: rgb(var(--sp-color-blue1-rgb)) !important;
      }
    }
  }
}

.modalContainer {
  margin-bottom: 256px;
  &__closeModalIcon {
    &:hover,
    &:focus {
      color: rgb(var(--sp-color-blue1-rgb)) !important;
    }
  }

  &__title {
    font-size: 24px;
    line-height: 32px;
    font-weight: 400;
  }
  &__text {
    display: block;
    font-size: 14px;
    color: black;
    line-height: 20px;
    margin-bottom: 6px;
  }

  &__goBackButton {
    line-height: var(--es-lh--16) !important;
    padding: 2px 12px 2px 12px !important;
    border-width: 2px !important;
    border-radius: 4px !important;
  }

  &__discardButton {
    line-height: var(--es-lh--16) !important;
    padding: 2px 12px 2px 12px !important;
    border-width: 2px !important;
    border-radius: 4px !important;
  }
}
