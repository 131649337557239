/*
!!!!! ALL SPECIFIC CSS PROPERTIES CREATE HERE NEED TO BE DELETED AS THE NEW css-properties.css FILE
!!!!! HANDLES ALL CSS PROPERTIES/VARIABLE MOVE FORWARD

!!!!!!!!!!  ONLY THE @import "pathToComponent-sassFile" SHOULD BE IN THIS FILE`
*/

& {
  /* Page Root */
  --ev-root-background-color: var(--es-color-grey0);
  --ev-root-font-color: var(--es-color-grey9);

  background-color: var(--es-root-background-color);
  color: var(--es-root-font-color);

  /* Accordion */
  @import "./components/Accordion.module.scss";

  /* Avatar */
  @import "./components/Avatar.module.scss";

  /* Badges */
  @import "./components/Badge.module.scss";

  /* BottomNavigation */
  @import "./components/BottomNavigation.module.scss";

  /* Box */
  @import "./components/Box.module.scss";

  /* Button */
  --ev-button-transition:
/**/ background-color var(--es-animation-hover), /**/ border-color var(--es-animation-hover),
    /**/ color var(--es-animation-hover);
  --ev-button-border-width: 2px;
  --ev-button-border-style: solid;
  --ev-button-margin: 6px 12px 6px 0px;
  --ev-button-inline-margin: 0px;
  --ev-button-inline-padding: 0px;
  --ev-button-text-transform: none;

  --ev-button-primary-color-background: var(--es-color-elsevier-blue);
  --ev-button-primary-color-font: var(--es-color-grey0);
  --ev-button-primary-color-background-hover: var(--es-color-grey0);
  --ev-button-primary-color-font-hover: var(--es-color-grey9);
  --ev-button-primary-color-border-hover: var(--es-color-a11y-orange);

  --ev-button-secondary-color-background: var(--es-color-grey0);
  --ev-button-secondary-color-background-hover: var(--es-color-grey0);
  --ev-button-secondary-color-border: var(--es-color-elsevier-blue);
  --ev-button-secondary-color-border-hover: var(--es-color-elsevier-orange);
  --ev-button-secondary-color-font: var(--es-color-grey9);
  --ev-button-secondary-color-font-hover: var(--es-color-a11y-orange);

  --ev-button-text-color-background: transparent;
  --ev-button-text-color-background-hover: transparent;
  --ev-button-text-color-border: transparent;
  --ev-button-text-color-border-hover: transparent transparent var(--es-color-elsevier-orange) transparent;
  --ev-button-text-color-font: var(--es-color-grey9);
  --ev-button-text-color-font-hover: var(--es-color-a11y-orange);
  --ev-button-text-colored-color-font: var(--es-color-elsevier-blue);
  --ev-button-text-padding: 0px;
  --ev-button-text-text-decoration-hover: underline;
  --ev-button-text-text-decoration-color-hover: var(--es-color-a11y-orange);
  --ev-button-text-text-decoration-thickness-hover: 2px;
  @import "./components/Button.module.scss";

  /* Chip */
  @import "./components/Chip.module.scss";

  /* CSS Grid */
  @import "./components/Grid.module.scss";

  /* CSS Grid */
  @import "./components/CSSGrid.module.scss";

  /* Checkbox */
  --ev-svg-background-fill: var(--es-color-grey0);
  --ev-svg-background-stroke: var(--es-color-grey9);
  --ev-svg-background-stroke-width: 2;
  --ev-svg-foreground-fill: var(--es-color-a11y-orange);
  @import "./components//Checkbox.module.scss";

  /* Dialog */
  --ev-dialog-background-color: var(--es-color-grey3);
  @import "./components/Dialog.module.scss";

  /* Divider */
  @import "./components/Divider.module.scss";

  /* Drawer */
  @import "./components/Drawer.module.scss";

  /* Input */
  @import "./components/Input.module.scss";

  /* Link */
  @import "./components/Link.module.scss";

  /* Ordered and UnOrdered List */
  @import "./components/List.module.scss";

  /* Modal */
  @import "./components/Modal.module.scss";

  /* Popper */
  @import "./components/Popper.module.scss";

  /* Radio */
  --ev-radio-svg-outer-color: var(--es-color-grey9);
  --ev-radio-svg-inner-color: var(--es-color-a11y-orange);
  @import "./components/Radio.module.scss";

  /* Select
  @import "./components/Select.module.scss";
  */

  /* Select Form Input */
  @import "./components/Select.module.scss";

  /* SkipLink */
  @import "./components/SkipLink.module.scss";

  /* Switch Grid */
  @import "./components/Switch.module.scss";

  /* Tables */
  @import "./components/Table.module.scss";

  /* Tabs */
  @import "./components/Tabs.module.scss";

  /* Typography */
  @import "./components/Typography.module.scss";

  /* Autosuggest */
  @import "./components/Autosuggest.module.scss";

  /* Tag */
  @import "./components/Tag.module.scss";
}
