a[class~="es-link"],
a[class~="es-button"],
button[class~="es-link"] {
  text-transform: var(--ev-button-text-transform);
  margin: unset;
  padding: unset;
  text-underline-offset: 0.15rem;
  align-items: center;
  background: unset;
  border: unset;
  display: inline-flex;
  font-size: var(--es-fs--16);

  &[class~="--inline"] {
    margin: var(--ev-button-inline-margin);
    padding: var(--ev-button-inline-padding);
  }

  &[class~="Mui-selected"] {
    color: var(--ev-button-primary-color-font);
  }
  &[class~="Mui-noborder"] {
    margin: 0;
  }

  &[class~="es-link__icon--left"],
  &[class~="es-link__icon--right"] {
    display: inline-flex;
    align-items: center;
  }
  &[class~="es-link__icon--left"] > svg {
    margin-right: var(--es-mp--04);
  }
  &[class~="es-link__icon--right"] > svg {
    margin-left: var(--es-mp--04);
  }

  &[class~="es-link__black--12-font"],
  &[class~="es-link__black--14-font"],
  &[class~="es-link__black--14-font--text-underline"],
  &[class~="es-link__blue--14-font"],
  &[class~="es-link__blue--16-font"],
  &[class~="es-link__black--16-font"],
  &[class~="es-link__black--16-font--text-underline"],
  &[class~="es-link__blue--18-font"],
  &[class~="es-link__blue--24-font"] {
    font-size: var(--es-fs--14);
    line-height: var(--es-lh--14);
    color: var(--es-color--blue-900);
    text-decoration: none;

    &:hover {
      background-color: var(--es-color--transparent);
      color: var(--es-color--blue-900);
      text-decoration: var(--ev-button-text-text-decoration-hover);
      text-decoration-color: var(--es-color--blue-900);
      text-decoration-thickness: var(--ev-button-text-text-decoration-thickness-hover);
      fill: rgb(var(--sp-color-blue0-rgb));
    }

    &[aria-current="page"] {
      color: var(--es-color--black);
      font-weight: var(--es-fw--heavy);
      text-decoration: underline;
    }

    &:focus-visible {
      outline: 2px solid rgb(var(--sp-color-blue0-rgb));
      outline-offset: 4px;
      border-radius: var(--es-fs--04);
    }

    &:disabled {
      color: var(--es-color--grey-600);
    }
  }

  &[class~="es-link__white--14-font"],
  &[class~="es-link__white--16-font"],
  &[class~="es-link__white--16-font--text-underline"],
  &[class~="es-link__white--18-font--text-underline"] {
    font-size: var(--es-fs--14);
    line-height: var(--es-lh--14);
    color: var(--es-color--blue-900);
    text-decoration: none;

    &:hover {
      background-color: var(--es-color--transparent);
      color: white;
      text-decoration: var(--ev-button-text-text-decoration-hover);
      text-decoration-color: white;
      text-decoration-thickness: var(--ev-button-text-text-decoration-thickness-hover);
      fill: rgb(var(--sp-color-blue0-rgb));
    }

    &:focus-visible {
      outline: 2px solid white;
      outline-offset: 4px;
      border-radius: var(--es-fs--04);
    }

    &:disabled {
      color: var(--es-color--grey-600);
    }
  }

  &[class~="es-link__black--12-font"],
  &[class~="es-link__black--14-font"],
  &[class~="es-link__black--16-font"],
  &[class~="es-link__black--16-font--text-underline"],
  &[class~="es-link__black--14-font--text-underline"] {
    color: var(--es-color--black);
  }

  &[class~="es-link__white--14-font"],
  &[class~="es-link__white--16-font"],
  &[class~="es-link__white--16-font--text-underline"],
  &[class~="es-link__white--18-font--text-underline"] {
    color: white;
  }

  &[class~="es-link__black--14-font--text-underline"],
  &[class~="es-link__black--16-font--text-underline"],
  &[class~="es-link__white--16-font--text-underline"],
  &[class~="es-link__white--18-font--text-underline"] {
    text-decoration: underline;
  }

  &[class~="es-link__black--12-font"] {
    font-size: var(--es-fs--12);
    line-height: var(--es-lh--12);
  }

  &[class~="es-link__blue--16-font"],
  &[class~="es-link__black--16-font"],
  &[class~="es-link__white--16-font"],
  &[class~="es-link__white--16-font--text-underline"],
  &[class~="es-link__black--16-font--text-underline"] {
    font-size: var(--es-fs--16);
    line-height: var(--es-lh--16);
  }

  &[class~="es-link__blue--18-font"],
  &[class~="es-link__white--18-font--text-underline"] {
    font-size: var(--es-fs--18);
    line-height: var(--es-lh--18);
  }

  &[class~="es-link__blue--24-font"] {
    font-size: var(--es-fs--24);
    line-height: var(--es-lh--24);
  }
}

a[class~="es-link--inline"],
button[class~="es-link--inline"] {
  margin: var(--ev-button-inline-margin);
  padding: var(--ev-button-inline-padding);
}
