/* Layout should have a root class of "main" that wraps all children for site layout */

.main > section {
  padding: 0;
  margin: 0px 32px;
}

/* All pages should be indented */
.main > * {
  padding: 0 8px;
}

/* Footer */
.ev__footer {
  margin: 2em 0 0 0;
  padding: 0;
}

/* EV uses consistent page header across the site */
.ev-pageheader {
  width: 100%;
  padding: 14px;
  background-color: var(--es-color-elsevier-green);
  color: white;
}

/*
* ! Conference Series/Events SectionNew
* !!  Main Container margin and padding updates
*/
.conference_series {
  margin: 0;
  padding: 0 2.5rem; // same as spacing={5} in the csp layout

  &__hero_banner {
    padding: 4rem 0 3.5rem 0; /* 64px 0px 56px 0px */

    & .empty-div {
      flex-basis: 25%;
      flex-grow: 0;
      max-width: 25%;
    }
  }
}
