[class~="es-skip-link"] {
  // background-color: var(--es-color-background);
  border: 0;
  clip: rect(0, 0, 0, 0);
  color: var(--es-color-foreground);
  height: 1px;
  // left: 6px;
  margin: -1px;
  overflow: hidden;
  padding: 4px;
  position: absolute !important;
  text-decoration: none;
  // top: 5px;
  width: 1px;
  z-index: 1000;
  &:focus-visible {
    clip: auto;
    height: auto;
    width: auto;
    outline: 2px solid rgb(var(--sp-color-blue0-rgb));
    outline-offset: 1px;
    border-radius: var(--es-fs--04);
  }
}
