/*
CSS Reset:

1. Use a more-intuitive box-sizing model.
*/
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* * {
  box-sizing: border-box;
  outline: 1px solid lime;
} */
/*
2. Remove default margin. padding and set font-family to inherit
*/
* {
  margin: 0;
  padding: 0;
  font: inherit;
}

/*
3. Allow percentage-based heights in the application
*/
html,
body {
  height: 100%;
  hanging-punctuation: first last;
  font-family: var(--es-ff--primary);
  font-variant-numeric: var(--es-fv--numeric);
}

/*
  Typographic tweaks!
4. Add accessible line-height
5. Improve text rendering
*/
body {
  min-height: 100svh;
  line-height: var(--es-lh--16);
  -webkit-font-smoothing: antialiased;
}

/*
6. Improve media defaults
*/
img,
picture,
video,
canvas,
svg {
  display: block;
  max-width: 100%;
}

/*
7. Remove built-in form typography styles
*/
input,
button,
textarea,
select {
  font: inherit;
}

/*
8. Avoid text overflows
*/
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
}

/* Improved heading in supported browsers */
h1,
h2,
h3,
h4,
h5,
h6 {
  text-wrap: balance;
}

/* improve readability with max-width on paragraphs and lists */
/* prevent orphans in supported browsers */
p,
li {
  /*  Take max-width off until UX has time to review and determine if we want to have a max-width for
  ** elements for readability and accessibility
  ! max-width: var(--p-max-width, 65ch);
  */
  text-wrap: pretty;
}

/* Improved image reset for accessibility features which will show alt attribute in italic if images off etc */
img {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
  font-style: var(--es-fst--italic);
  background-repeat: no-repeat;
  background-size: cover;
  shape-margin: 0.75rem;
}

@media print {
  .ev--nonprintable {
    display: none !important;
  }
}
