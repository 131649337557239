/*
**  Table Reset
*/
table[class~="MuiTable-root"] {
  border: unset;
  border-collapse: collapse;
  border-spacing: 0;
  margin: 0 auto 1.5em auto;

  thead,
  .MuiTableHead-root,
  tbody,
  .MuiTableBody-root,
  tfoot,
  .MuiTableFoot-root {
    border: none;
  }

  tr,
  .MuiTableRow-root,
  th,
  td,
  .MuiTableCell-root {
    border: none;
    color: var(--es-color-grey9);
    font-size: var(--es-font-size-16);
    line-height: var(--es-font-size-24);
    padding: 0;
  }

  th {
    padding: 0 0 1rem 0; // 16px bottom
  }
}
/*
**  End Table Reset
**
**  Base Table Style
*/
table[class~="es-table"] {
  & thead {
    border-bottom: 1px solid var(--es-color-grey3);

    & th {
      // padding: .5em 1em 1em 0;
      font-family: var(--es-font-sans-serif);

      &[class~="right_aligned"] {
        //  THIS NEEDS TO ALIGN WITH THE tbody td BELOW, AS WELL
        text-align: right;
        padding-right: 2.5em;
      }
    }
  }
  & tbody {
    & tr {
      border-bottom: 1px solid var(--es-color-grey3);
    }

    & tr,
    & .MuiTableRow-root,
    & td,
    & .MuiTableCell-root {
      // padding: 1em 0 .5em 0;
      text-align: left;
      font-family: var(--es-font-sans-serif);
    }

    & td {
      min-height: 4rem; //    Set a minimum height of 71px per Figma
      vertical-align: top;
      padding: 1em 1em 1em 0;

      & button {
        display: inline-block; // set display to inline-block as defaul for MUI Button is flexbox
        border: unset;
        margin: 0;
        padding: 0;
        text-align: left;
      }
      /*
            **  Below is to set the character width for the cells that contain titles and other text
            **  so the text does not get too long and skew table columns
            */
      &[class~="char_80_width"] {
        width: 80ch;
      }

      &[class~="char_60_width"] {
        width: 60ch;
      }

      &[class~="char_50_width"] {
        width: 50ch;
      }

      &[class~="char_40_width"] {
        width: 40ch;
      }

      &[class~="char_30_width"] {
        width: 30ch;
      }
      /*
            **  Below is to set a right aligned cell to match figma designs.
            **  THIS NEEDS TO ALIGN WITH THE thead th ABOVE AS WELL
            */
      &[class~="right_aligned"] {
        text-align: right;
        padding-right: 2.5em;
        /*
                **  Need to discuss the below.  We should implement class on button component to pass to have blue
                **  colored buttons and/or different colored buttons.
                */
        & button {
          color: rgb(var(--sp-color-blue0-rgb));
          text-align: right;
          vertical-align: top;
        }
      }
    }
  }
}
