/*
**  Use BEM (Block__Element--Modifier) naming convention
*/
:root {
  /*
    Typography - Font Families --es-ff (FontFamily)
    */
  --es-ff--primary: ElsevierSans;
  --es-ff--secondary: ElsevierDisplay;
  --es-ff--tertiary: ElsevierDisplay-Light;
  --es-ff--quartary: ElsevierGulliver;
  --es-ff--quinary: NexusSerif;
  /*
    Font-Weight and Styles
    */
  --es-ff--primary-bold: ElsevierSans-Bold;
  --es-ff--primary-italic: ElsevierSans-Italic;
  --es-ff--primary-bold-italic: ElsevierSans-Bold-Italic;

  --es-ff--secondary-bold: ElsevierDisplay-Bold;
  --es-ff--secondary-italic: ElsevierDisplay-Italic;

  --es-ff--tertiary-italic: ElsevierDisplay-Light-Italic;

  --es-ff--quartary-bold: ElsevierGulliver-Bold;
  --es-ff--quartary-italic: ElsevierGulliver-Italic;
  --es-ff--quartary-bold-italic: ElsevierGulliver-Bold-Italic;

  --es-ff--quinary-bold: NexusSerif-Bold;
  --es-ff--quinary-italic: NexusSerif-Italic;

  /*
    Font Sizing - Based off of default 16px browser default --es-fs (FontSize)
    So --01 = 1px --02 = 2px etc...
    */
  --es-fs--01: 0.0625rem;
  --es-fs--02: 0.125rem;
  --es-fs--04: 0.24rem;
  --es-fs--06: 0.375rem;
  --es-fs--08: 0.5rem;
  --es-fs--10: 0.625rem;
  --es-fs--12: 0.75rem;
  --es-fs--14: 0.875rem;
  --es-fs--16: 1rem;
  --es-fs--18: 1.125rem;
  --es-fs--20: 1.25rem;
  --es-fs--24: 1.5rem;
  --es-fs--26: 1.625rem;
  --es-fs--28: 1.75rem;
  --es-fs--32: 2rem;
  --es-fs--38: 2.375rem;
  --es-fs--40: 2.5rem;
  --es-fs--48: 3rem;
  --es-fs--56: 3.5rem;
  --es-fs--64: 4rem;
  --es-fs--80: 5rem;

  /*
    Font Weight, Style and Variant --es-fw (FontWeight) --es-fst (FontStyle) --es-fv (FontVariant)
    */
  --es-fw--light: 300;
  --es-fw--regular: 400;
  --es-fw--bold: 500;
  --es-fw--heavy: 700;

  --es-fst--regular: normal;
  --es-fst--italic: italic;
  --es-fst--oblique: oblique;

  --es-fv--caps-regular: normal;
  --es-fv--caps-small-caps: small-caps;
  --es-fv--caps-all: all-small-caps;
  --es-fv--numeric: lining-nums proportional-nums;
  --es-fv--tabular: lining-nums tabular-nums;

  /*
    *   Border radius --es-br--# (border-radius--size)
    */
  --es-br--xs: var(--es-fs--01);
  --es-br--s: var(--es-fs--02);
  --es-br--m: var(--es-fs--04);
  --es-br--l: var(--es-fs--06);
  --es-br--xl: var(--es-fs--08);
  --es-br--xxl: var(--es-fs--10);
  --es-br--xxxl: var(--es-fs--16);

  /*
    Line Height --es-lh--## (LineHeight--FontSize) -- Based off default 16px browser default
    */
  --es-lh--40: var(--es-fs--48);
  --es-lh--32: var(--es-fs--40);
  --es-lh--24: var(--es-fs--32);
  --es-lh--20: var(--es-fs--28);
  --es-lh--18: var(--es-fs--26);
  --es-lh--16: var(--es-fs--24);
  --es-lh--14: var(--es-fs--20);
  --es-lh--12: var(--es-fs--16);

  /*
    Margin/Padding - Based off of default 16px browser default --es-mp (Margin/Padding) - conversion from rem to em
    */
  --es-mp--01: 0.0625rem;
  --es-mp--02: 0.125rem;
  --es-mp--04: 0.24rem;
  --es-mp--05: 0.3025rem;
  --es-mp--06: 0.375rem;
  --es-mp--08: 0.5rem;
  --es-mp--10: 0.625rem;
  --es-mp--12: 0.75rem;
  --es-mp--14: 0.875rem;
  --es-mp--16: 1rem;
  --es-mp--18: 1.125rem;
  --es-mp--20: 1.25rem;
  --es-mp--24: 1.5rem;
  --es-mp--28: 1.75rem;
  --es-mp--32: 2rem;
  --es-mp--40: 2.5rem;
  --es-mp--48: 3rem;
  --es-mp--56: 3.5rem;
  --es-mp--64: 4rem;
  /*
    Core Color Pallet:
    Based off the Graphene "pallete-es-light-24" theme
    KEEP COLORS GROUPED TOGETHER AND USE NUMERIC VALUE FOR LUMINOUS
    --es-color--white-hsl: 100, 100%, 100%;
    --es-color--gray-hsl: 216, 20%, 42%;

    ! NOTE: For the color lumuinous - the lower the last number the lighter it is
    ! For example: Greyscale --es-color-grey-100 is the lightest grey used with --es-color-grey-300 being the darkest
    *   Color Values
    */

  --es-color--white-hsl: 0, 0%, 100%; /*  #FFFFFF */
  --es-color--black-hsl: 0, 0%, 0; /*  #000000 */
  --es-color--transparent: transparent; /*  Transparent */

  --es-color--grey-100-hsl: 210, 20%, 98%; /*  #F9FAFB */
  --es-color--grey-200-hsl: 225, 20%, 96%; /*  #F3F4F7 */
  --es-color--grey-300-hsl: 220, 20%, 94%; /*  #EDEFF3 */
  --es-color--grey-400-hsl: 218, 20%, 92%; /*  #E7EAEF */
  --es-color--grey-500-hsl: 218, 22%, 90%; /*  #E0E4EB */
  --es-color--grey-600-hsl: 218, 14%, 62%; /*  #919BAC */
  --es-color--grey-700-hsl: 216, 20%, 42%; /*  #566780 */
  --es-color--grey-800-hsl: 217, 21%, 12%; /*  #181D25 */
  --es-color--grey-900-hsl: 213, 22%, 92%; /*  #E6EAEF */
  --es-color--grey-1000-hsl: 225, 27%, 94%; /*  #ECEEF4 */

  --es-color--blue-100-hsl: 200, 100%, 92%; /*  #D6F1FF */
  --es-color--blue-200-hsl: 215, 100%, 92%; /*  #D6E7FF */
  --es-color--blue-300-hsl: 216, 100%, 90%; /*  #CCE0FF */
  --es-color--blue-400-hsl: 216, 100%, 96%; /*  #EBF3FF */
  --es-color--blue-500-hsl: 216, 83%, 78%; /*  #A4C0EA */
  --es-color--blue-600-hsl: 216, 63%, 70%; /*  #82A9E3 */
  --es-color--blue-700-hsl: 216, 63%, 62%; /*  #6192DB */
  --es-color--blue-800-hsl: 200, 100%, 32%; /*  #006DA3 */
  --es-color--blue-900-hsl: 216, 100%, 42%; /*  #0056D6 */
  --es-color--blue-1000-hsl: 216, 100%, 34%; /*  #0046AD */
  --es-color--blue-1100-hsl: 216, 100%, 26%; /*  #003585 */
  --es-color--blue-1200-hsl: 216, 63%, 16%; /*  #0F2442 */
  --es-color--blue-1300-hsl: 197, 88%, 14%; /*  #043243 */
  --es-color--blue-1400-hsl: 196, 90%, 10%; /*  #032430 */
  --es-color--blue-1500-hsl: 201.15, 100%, 23.92%; /*  #004F7A */

  --es-color--green-100-hsl: 137, 85%, 92%; /*  #D9FCE3 */
  --es-color--green-200-hsl: 137, 83%, 26%; /*  #0B7A2B */
  --es-color--green-300-hsl: 137, 84%, 10%; /*  #042F10 */

  --es-color--yellow-100-hsl: 50, 100%, 88%; /*  #FFF5C2 */
  --es-color--yellow-200-hsl: 50, 100%, 24%; /*  #7A6600 */
  --es-color--yellow-300-hsl: 50, 100%, 12%; /*  #3D3300 */

  --es-color--red-100-hsl: 356, 76%, 92%; /*  #FADBDD */
  --es-color--red-200-hsl: 6, 100%, 86%; /*  #FFBFB8 */
  --es-color--red-300-hsl: 357, 76%, 42%; /*  #BC1A23 */
  --es-color--red-400-hsl: 357, 76%, 34%; /*   #98151C */
  --es-color--red-500-hsl: 357, 76%, 26%; /*  #751015 */

  --es-color--orange-100-hsl: 15, 100%, 51%; /*  #FF4203 */

  /*
    !   Color Variables - Use these within your .scss files     !
    */
  --es-color--black: hsl(var(--es-color--black-hsl));
  --es-color--white: hsl(var(--es-color--white-hsl));

  --es-color--grey-100: hsl(var(--es-color--grey-100-hsl));
  --es-color--grey-200: hsl(var(--es-color--grey-200-hsl));
  --es-color--grey-300: hsl(var(--es-color--grey-300-hsl));
  --es-color--grey-400: hsl(var(--es-color--grey-400-hsl));
  --es-color--grey-500: hsl(var(--es-color--grey-500-hsl));
  --es-color--grey-600: hsl(var(--es-color--grey-600-hsl));
  --es-color--grey-700: hsl(var(--es-color--grey-700-hsl));
  --es-color--grey-800: hsl(var(--es-color--grey-800-hsl));
  --es-color--grey-900: hsl(var(--es-color--grey-900-hsl));
  --es-color--grey-1000: hsl(var(--es-color--grey-1000-hsl));

  --es-color--blue-100: hsl(var(--es-color--blue-100-hsl));
  --es-color--blue-200: hsl(var(--es-color--blue-200-hsl));
  --es-color--blue-300: hsl(var(--es-color--blue-300-hsl));
  --es-color--blue-400: hsl(var(--es-color--blue-400-hsl));
  --es-color--blue-500: hsl(var(--es-color--blue-500-hsl));
  --es-color--blue-600: hsl(var(--es-color--blue-600-hsl));
  --es-color--blue-700: hsl(var(--es-color--blue-700-hsl));
  --es-color--blue-800: hsl(var(--es-color--blue-800-hsl));
  --es-color--blue-900: hsl(var(--es-color--blue-900-hsl));
  --es-color--blue-1000: hsl(var(--es-color--blue-1000-hsl));
  --es-color--blue-1100: hsl(var(--es-color--blue-1100-hsl));
  --es-color--blue-1200: hsl(var(--es-color--blue-1200-hsl));
  --es-color--blue-1300: hsl(var(--es-color--blue-1300-hsl));
  --es-color--blue-1400: hsl(var(--es-color--blue-1400-hsl));
  --es-color--blue-1500: hsl(var(--es-color--blue-1500-hsl));

  --es-color--green-100: hsl(var(--es-color--green-100-hsl));
  --es-color--green-200: hsl(var(--es-color--green-200-hsl));
  --es-color--green-300: hsl(var(--es-color--green-300-hsl));

  --es-color--yellow-100: hsl(var(--es-color--yellow-100-hsl));
  --es-color--yellow-200: hsl(var(--es-color--yellow-200-hsl));
  --es-color--yellow-300: hsl(var(--es-color--yellow-300-hsl));

  --es-color--red-100: hsl(var(--es-color--red-100-hsl));
  --es-color--red-200: hsl(var(--es-color--red-200-hsl));
  --es-color--red-300: hsl(var(--es-color--red-300-hsl));
  --es-color--red-400: hsl(var(--es-color--red-400-hsl));
  --es-color--red-500: hsl(var(--es-color--red-500-hsl));

  --es-color--orange-100: hsl(var(--es-color--orange-100-hsl));

  /*
    *   Text --es-fc (Font Color)
    */
  --es-fc--primary: var(--es-color--black);
  --es-fc--secondary: var(--es-color--grey-700);
  --es-fc--inverse: var(--es-color--white);
  --es-fc--disabled: var(--es-color--grey-600);
  --es-fc--inter-idle: var(--es-color--blue-900);
  --es-fc--inter-hover: var(--es-color--blue-1000);
  --es-fc--inter-press: var(--es-color--blue-1100);
  --es-fc--brand: var(--es-color--orange-100);
  --es-fc--error: var(--es-color--red-300);
  --es-fc--error-2: var(--es-color--red-500);
  --es-fc--success: var(--es-color--green-200);
  --es-fc--success-2: var(--es-color--green-300);
  --es-fc--inform-2: var(--es-color--blue-800);
  --es-fc--warning-2: var(--es-color--yellow-300);

  /*
    *   sc --es-sc (Shape Color)
    */
  --es-sc--primary: var(--es-color--black);
  --es-sc--secondary: var(--es-color--grey-700);
  --es-sc--inverse: var(--es-color--white);
  --es-sc--disabled: var(--es-color--grey-600);
  --es-sc--inter-idle: var(--es-color--blue-900);
  --es-sc--inter-hover: var(--es-color--blue-1000);
  --es-sc--inter-press: var(--es-color--blue-1000);
  --es-sc--brand: var(--es-color--orange-100);
  --es-sc--success: var(--es-color--green-200);
  --es-sc--error-idle: var(--es-color--red-300);
  --es-sc--error-hover: var(--es-color--red-300);
  --es-sc--error-press: var(--es-color--red-300);
  --es-sc--inverse-idle: var(--es-color--blue-700);
  --es-sc--inverse-hover: var(--es-color--blue-600);
  --es-sc--inverse-press: var(--es-color--blue-500);
  --es-sc--error-disable: var(--es-color--red-200);
  --es-sc--logo-pic: var(--es-color--black);
  --es-sc--logo-wm: var(--es-color--orange-100);

  /*
    *   Border --es-bc (Border Color)
    */
  --es-bc--primary: var(--es-color--black);
  --es-bc--secondary: var(--es-color--grey-700);
  --es-bc--interact-idle: var(--es-color--blue-900);
  --es-bc--interact-hover: var(--es-color--blue-1000);
  --es-bc--interact-press: var(--es-color--blue-1100);
  --es-bc--error-idle: var(--es-color--red-300);
  --es-bc--inform: var(--es-color--blue-800);
  --es-bc--success: var(--es-color--green-200);
  --es-bc--warning: var(--es-color--yellow-200);
  --es-bc--disabled: var(--es-color--grey-600);

  /*
    *   Suface - aka background --es-bg (Surface/Background Color)
    */
  --es-bg--s0: var(--es-color--white);
  --es-bg--s1: var(--es-color--grey-100);
  --es-bg--s2: var(--es-color--grey-200);
  --es-bg--s3: var(--es-color--grey-300);
  --es-bg--s4: var(--es-color--grey-400);
  --es-bg--s5: var(--es-color--grey-500);
  --es-bg--error: var(--es-color--red-100);
  --es-bg--disabled: var(--es-color--grey-600);
  --es-bg--inform: var(--es-color--blue-100);
  --es-bg--success: var(--es-color--green-100);
  --es-bg--warning: var(--es-color--yellow-100);
  --es-bg--inter-idle: var(--es-color--blue-400);
  --es-bg--inter-hover: var(--es-color--blue-200);
  --es-bg--inter-press: var(--es-color--blue-300);
  --es-bg--primary: var(--es-color--blue-900);
  --es-bg--invert-idle: var(--es-color--blue-1400);
  --es-bg--invert-hover: var(--es-color--blue-1300);
  --es-bg--invert-press: var(--es-color--blue-1200);

  /*
    *   Animation --es-animation-
    */
  --es-animation--appear: 0.3s;
  --es-animation--hover: 0.3s;
  --es-animation--shift: 0.2s;

  /*
    *   Transformations --es-transform-
    */
  --es-transform--none: none;
  --es-transform--cap: capitalize;
  --es-transform--upper: uppercase;
  --es-transform--lower: lowercase;
  /*
    *   List Types --es-ls (List Style)
    */
  --es-ls--none: none;
  --es-ls--square: square;
  --es-ls--inside: inside;
  --es-ls--disc: disc; /* ! Default Value if style is not set */
  /*
    !    list-style-image -- needs a URL to the image so set on individual components or pages as needed
    !    list-style-position -- set on individual components or pages as needed
    */
}
