[class~="es-bottom-navigation"] {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  flex-direction: unset;
  flex-wrap: unset;
  color: var(--es-color-grey5);
  height: 32px;
  margin: 6px 0 18px 0;
  border-bottom: 1px solid var(--es-color-grey2);

  & [class~="MuiBottomNavigationAction-label"] {
    font-size: 1rem;
  }

  & [class~="es-bottom-navigation-action"] {
    align-items: flex-start;
    padding: 8px 8px 0 0;
    margin: 0;
    &[class~="Mui-selected"] {
      color: var(--es-color-elsevier-orange);

      & [class~="MuiBottomNavigationAction-label"] {
        border-bottom: 2px solid var(--es-color-elsevier-orange);
      }
    }
  }
}
