.es-global-header {
  padding: var(--es-fs--16) var(--es-fs--16) var(--es-fs--16) var(--es-fs--32);

  &__parent {
    &--logo {
      flex: 0 0 234px;
    }

    &--nav {
      display: flex;
      flex-basis: 100%;
      justify-content: flex-end;

      &__main-links {
        z-index: 10;
        max-width: fit-content;

        [aria-current="page"] {
          text-decoration-line: underline;
          text-decoration-color: var(--es-color--black);
        }

        &__alerts-more,
        &__records-more {
          display: none;
        }
      }

      &__support-links {
        z-index: 10;
        max-width: fit-content;

        &__help {
          max-width: fit-content;
        }

        &__institution {
          max-width: fit-content;
        }
      }

      &__sub-menu {
        width: 100%;

        h2 {
          color: var(--es-color-grey6);
          font-size: var(--es-fs--14);
          line-height: var(--es-lh--14);
        }

        &__list {
          display: flex;
          flex-direction: column;
          justify-content: center;
          list-style-type: none;
          gap: var(--es-mp--12);
          padding: 0;
          margin: 0;

          & li {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
          }

          &--results-number {
            padding-inline-end: var(--es-mp--12);
          }

          &--flex-end {
            align-items: center;
            border-top: 1px solid var(--es-color-grey4);
            display: inline-flex;
            justify-content: flex-end;
            margin-top: var(--es-mp--12);
            padding-block: var(--es-mp--08) 0;
            width: 100%;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1124px) {
  .es-global-header {
    &__parent {
      &--nav {
        &__main-links {
          &__alerts,
          &__records {
            display: none;
          }
          &__alerts-more,
          &__records-more {
            display: block;
          }
        }
      }
    }
  }
}
