span[class~="es-check"] {
  & [class~="__svgIcon-background"] {
    fill: var(--ev-svg-background-fill);
    stroke: var(--ev-svg-background-stroke);
    stroke-width: var(--ev-svg-background-stroke-width);
    &:hover,
    &:focus {
      stroke: black;
    }
  }
  & [class~="__svgIcon-foreground"] {
    fill: var(--ev-svg-foreground-fill);
  }
  svg {
    &:focus,
    &:hover {
      & [class~="__svgIcon-background"] {
        // stroke: blue;
      }
      // border-color: blue;
      border-width: 5px;
    }
  }
}

input[class~="es-check"] {
  -webkit-appearance: none;
  background-color: var(--es-color-background);
  border: 2px solid var(--es-color-border);
  box-shadow: 0 0 0 0.15em var(--es-color-background) inset;
  box-sizing: border-box;
  display: inline-block;
  font: inherit;
  height: calc(1em + 4px);
  margin: 0;
  padding: 0;
  position: relative;
  vertical-align: middle;
  width: calc(1em + 4px);
  transition: background-color var(--es-animation-hover), border-color var(--es-animation-hover);
}

label[class~="hideCheckboxLabel"] {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

/* Checkbox sizes, by default size is inherited from the parent */
/* These styles were copied from the Elsevier guidelines page for a checkbox */
label[class~="es-reg-check"].small,
input[class~="es-check-input"].small {
  font-size: var(--es-font-size-16);
  line-height: var(--es-line-height);
}
label[class~="es-reg-check"].medium,
input[class~="es-check-input"].medium {
  font-size: var(--es-font-size-20);
  line-height: var(--es-line-height);
}
label[class~="es-reg-check"].large,
input[class~="es-check-input"].large {
  font-size: var(--es-font-size-24);
  line-height: var(--es-line-height);
}

input[class~="es-check-input"] {
  -webkit-appearance: none;
  background-color: var(--es-color-background);
  border: 2px solid var(--es-color-border);
  box-shadow: 0 0 0 0.15em var(--es-color-background) inset;
  box-sizing: border-box;
  display: inline-block;
  font: inherit;
  height: calc(1em + 4px);
  margin: 0;
  padding: 0;
  position: relative;
  vertical-align: middle;
  width: calc(1em + 4px);

  transition: background-color var(--es-animation-hover), border-color var(--es-animation-hover);
}

input[class~="es-check-input"]:hover {
  border-color: var(--es-color-a11y-orange);
}

input[class~="es-check-input"]:checked {
  background-color: var(--es-color-a11y-orange);
}

label > input[class~="es-check-input"] {
  margin-right: 0.5em;
}
input[class~="es-check-input"] + label {
  margin-left: 0.5em;
  vertical-align: middle;
}
label[class~="es-reg-check"] > span {
  vertical-align: middle;
}

/* Disabled checkbox */
input[class~="es-check-input"][disabled] {
  border: 2px solid var(--es-color-disabled) !important;
  background-color: var(--es-color-background) !important;
}
input[class~="es-check-input"][disabled]:before {
  border-color: var(--es-color-disabled) !important;
}
input[class~="es-check-input"][disabled]:checked,
input[class~="es-check-input"][disabled][type="checkbox"]:indeterminate {
  background-color: var(--es-color-disabled) !important;
}
