$height: 48px;

.select {
  position: relative;
  display: block;
  
  &, & * {
    box-sizing: border-box !important;
  }
  > span {
    display: block;
    height: 24px;
    line-height: 24px;
    margin-bottom: 4px;
    user-select: none;
  }
  > label {
    margin:  0 !important;
    position: static !important;
    padding: 0 !important;
    &::before, &::after {
      display: none !important;
    }
  }
  select {
    background-color: white !important;
    border: 1px solid #566780 ;
    border-radius: 8px !important;
    appearance: none !important;
    width: 100%;
    padding: 0 $height 0 1rem !important;
    font-weight: 400;
    color: #566780;
    height: $height !important;
    line-height: $height;
    transition: all 100ms;
    outline: unset !important;
    font-size: var(--es-font-size-16);

    &:hover {
      border: 2px solid #566780;
    }
    &:focus {
      border-width: 2px;
      color: black;
      border-color: black;
    }
  }
  svg {
    color: #0056D6 !important;
    right: 0.9rem !important;
    bottom: 0.9rem !important;
    top: unset !important;
    pointer-events: none;
    text-align: center;
    width: 12px !important;
  }
}
