.notifications {
  position: fixed;
  bottom: 3%;
  right: 2%;
  text-align: right;
  width: 400px;
  max-width: 100%;
  padding: 1rem;
  z-index: 1000;
  box-sizing: border-box;

  & > div {
    position: relative;
  }
  & > div:not(:last-child) {
    margin-bottom: 1rem;
  }
}

.notification {
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0;

  & svg {
    font-size: 20px;
    margin-top: -1px;
  }

  &[class~="info"] {
    background-color: black;
    color: white;
    display: inline-flex;
    text-wrap: nowrap;
  }

  &[class~="success"] {
  }
  &[class~="warning"] {
  }
  &[class~="error"] {
  }
}
