.feedback-area {
  right: 2%;
  position: fixed;
  z-index: 600;
  bottom: 0px;
  width: 150px;
  line-height: 2rem;
  margin-right: 55px;
}

.feedback-button {
  padding: 4px 10px !important;
  border-top: 2px solid #c9c9c9 !important;
  border-left: 2px solid #c9c9c9 !important;
  border-right: 2px solid #c9c9c9 !important;
  border-bottom: none !important;
  font-size: 1rem;
  background-color: white !important;
  color: #505050 !important;
  text-decoration: none;
  margin: 0px !important;
  > svg {
    top: 1px;
    height: 18px;
    width: 25px;
    position: relative;
    color: #4e9eb7;
  }
}

.feedback-button:hover,
.feedback-button:focus {
  background-color: #e9711c !important;
  color: #ffffff !important;
  .ss-chat {
    color: white;
  }
}
