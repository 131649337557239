.ai__banner {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  text-align: center;

  height: var(--es-fs--48);
  background-color: rgb(var(--es-color-elsevier-green-rgb));
  color: var(--es-color--white);

  &__icon {
    margin-right: var(--es-fs--08);
    height: var(--es-fs--24);
  }

  &__title {
    margin-block: unset;
    margin-inline: 0 var(--es-fs--16);
  }

  &__link {
    cursor: pointer;
  }
}
