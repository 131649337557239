div[class~="es-avatar"] {
  display: flex;
  flex-direction: row;
  background-color: var(--es-color-base-dark-grey);
  font-size: 1.25rem;

  [class*="es--box"] {
    margin-left: 12px;
  }
}
