/* Elsevier Sans */
@font-face {
  font-display: swap;
  font-family: "ElsevierSans";
  src: url("../../fonts/ElsevierSans/ElsevierSansWeb-Regular.woff2") format("woff2");
}
@font-face {
  font-display: swap;
  font-family: "ElsevierSans-Bold";
  font-weight: bold;
  src: url("../../fonts/ElsevierSans/ElsevierSansWeb-Bold.woff2") format("woff2");
}
@font-face {
  font-display: swap;
  font-family: "ElsevierSans-Italic";
  font-style: italic;
  src: url("../../fonts/ElsevierSans/ElsevierSansWeb-RegularItalic.woff2") format("woff2");
}
@font-face {
  font-display: swap;
  font-family: "ElsevierSans-Bold-Italic";
  font-style: italic;
  font-weight: bold;
  src: url("../../fonts/ElsevierSans/ElsevierSansWeb-BoldItalic.woff2") format("woff2");
}

/* Elsevier Display */
@font-face {
  font-display: swap;
  font-family: "ElsevierDisplay";
  src: url("../../fonts/ElsevierDisplay/ElsevierDisplay-Regular-Condensed.woff2") format("woff2");
}
@font-face {
  font-display: swap;
  font-family: "ElsevierDisplay-Bold";
  font-weight: bold;
  src: url("../../fonts/ElsevierDisplay/ElsevierDisplay-Bold-Condensed.woff2") format("woff2");
}
@font-face {
  font-display: swap;
  font-family: "ElsevierDisplay-Italic";
  font-style: italic;
  src: url("../../fonts/ElsevierDisplay/ElsevierDisplay-LightItalic.woff2") format("woff2");
}

/* Elsevier Display Light */
@font-face {
  font-display: swap;
  font-family: "ElsevierDisplay-Light";
  src: url("../../fonts/ElsevierDisplayLight/ElsevierDisplay-Light.woff2") format("woff2");
}
@font-face {
  font-display: swap;
  font-family: "ElsevierDisplay-Light-Italic";
  font-style: italic;
  src: url("../../fonts/ElsevierDisplayLight/ElsevierDisplay-LightItalic.woff2") format("woff2");
}

/* Elsevier Gulliver */
@font-face {
  font-display: swap;
  font-family: "ElsevierGulliver";
  src: url("../../fonts/ElsevierGulliver/ElsevierGulliver-Regular.woff2") format("woff2");
}
@font-face {
  font-display: swap;
  font-family: "ElsevierGulliver-Bold";
  font-weight: bold;
  src: url("../../fonts/ElsevierGulliver/ElsevierGulliver-Bold.woff2") format("woff2");
}
@font-face {
  font-display: swap;
  font-family: "ElsevierGulliver-Italic";
  font-style: italic;
  src: url("../../fonts/ElsevierGulliver/ElsevierGulliver-Italic.woff2") format("woff2");
}
@font-face {
  font-display: swap;
  font-family: "ElsevierGulliver-Bold-Italic";
  font-style: italic;
  font-weight: bold;
  src: url("../../fonts/ElsevierGulliver/ElsevierGulliver-BoldItalic.woff2") format("woff2");
}

/* NexusSans
! This is commented out as this is now an outdated typeface - Commented out as may have to
! turn back on if issues with line-height etc.
@font-face {
    font-display: swap;
    font-family: 'NexusSans';
    src: url('../../fonts/NexusSansWebPro/NexusSansWebPro-Regular.woff2') format('woff2');
}
@font-face {
  font-display: swap;
  font-family: 'NexusSans';
  font-weight: bold;
  src: url('../../fonts/NexusSansWebPro/NexusSansWebPro-Bold.woff2') format('woff2');
}
@font-face {
  font-display: swap;
  font-family: 'NexusSans';
  font-style: italic;
  src: url('../../fonts/NexusSansWebPro/NexusSansWebPro-Italic.woff2') format('woff2');
}
@font-face {
  font-display: swap;
  font-family: 'NexusSans';
  font-style: italic;
  font-weight: bold;
  src: url('../../fonts/NexusSansWebPro/NexusSansWebPro-BoldItalic.woff2') format('woff2');
}
*/

/* NexusSerif */
@font-face {
  font-display: swap;
  font-family: "NexusSerif";
  src: url("../../fonts/NexusSerif/NexusSerifWebPro-Regular.woff2") format("woff2");
}
@font-face {
  font-display: swap;
  font-family: "NexusSerif-Bold";
  font-weight: bold;
  src: url("../../fonts/NexusSerif/NexusSerifWebPro-Bold.woff2") format("woff2");
}
@font-face {
  font-display: swap;
  font-family: "NexusSerif-Italic";
  font-style: italic;
  font-weight: bold;
  src: url("../../fonts/NexusSerif/NexusSerifWebPro-BoldItalic.woff2") format("woff2");
}
