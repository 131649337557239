/*
    ** Graphene Primiative Colors **
    !! https://graphene.elsevier.com/#/foundations/primitives/colors/
*/
:root {
  /* Neutral */
  --gr-neutral-white-l100: #ffffff;
  --gr-neutral-black-l0: #000000;

  /* Grey
    To avoid confusion between two colors named Grey we have renamed the original Elsevier Grey to Ash.
*/
  --gr-grey-100-l98: #f9fafb;
  --gr-grey-200-l96: #f2f4f7;
  --gr-grey-300-l94: #eceef4;
  --gr-grey-400-l90: #dfe1ec;
  --gr-grey-500-l82: #c6cadd;
  --gr-grey-600-l62: #868fb6;
  --gr-grey-700-l42: #505c86;
  --gr-grey-800-l26: #323953;
  --gr-grey-900-l18: #1b1e2d;
  --gr-grey-1000-l14: #1b1e2d;
  --gr-grey-1100-l12: #171a26;
  --gr-grey-1200-l10: #131620;

  /* Ash
    !! To avoid confusion between two colors named Grey we have renamed the original Elsevier Grey to Ash.
*/
  --gr-ash-100-l98: #fafafa;
  --gr-ash-200-l96: #f5f5f5;
  --gr-ash-300-l94: #f0f0f0;
  --gr-ash-400-l90: #e6e6e6;
  --gr-ash-500-l82: #d1d1d1;
  --gr-ash-600-l66: #a8a8a8;
  --gr-ash-700-l42: #6b6b6b;
  --gr-ash-800-l26: #424242;
  --gr-ash-900-l18: #2e2e2e;
  --gr-ash-1000-l14: #242424;
  --gr-ash-1100-l12: #1f1f1f;
  --gr-ash-1200-l10: #1a1a1a;

  /* Blue
    !! To avoid confusion between two colors named Blue we have renamed the original Elsevier Blue to Petrol.
*/
  --gr-blue-100-l96: #eef3fb;
  --gr-blue-200-l92: #dee8f7;
  --gr-blue-300-l88: #cdddf4;
  --gr-blue-400-l76: #9bb9e8;
  --gr-blue-500-l63: #9bb9e8;
  --gr-blue-600-l66: #0a6dff;
  --gr-blue-700-l42: #0056d6; /* Elsevier source --gr */
  --gr-blue-800-l26: #003485;
  --gr-blue-900-l18: #00245c;
  --gr-blue-1000-l14: #001c47;
  --gr-blue-1100-l12: #00183d;
  --gr-blue-1200-l10: #001433;

  /* Petrol
    !! To avoid confusion between two colors named Blue we have renamed the original Elsevier Blue to Petrol.
*/
  --gr-petrol-100-l96: #ebf8ff;
  --gr-petrol-200-l94: #e0f4ff;
  --gr-petrol-300-l92: #d6f1ff;
  --gr-petrol-400-l88: #c2eaff;
  --gr-petrol-500-l80: #99dbff;
  --gr-petrol-600-l64: #47beff;
  --gr-petrol-700-l48: #009ef5;
  --gr-petrol-800-l32: #006aa3;
  --gr-petrol-900-l24: #004f7a;
  --gr-petrol-1000-l20: #004266;
  --gr-petrol-1100-l18: #003b5c;
  --gr-petrol-1200-l16: #003552;

  /* Orange
    !! To avoid confusion between two colors named Orange we have renamed the original Elsevier Orange to Marigold.
*/
  --gr-orange-100-l98: #fff8f5;
  --gr-orange-200-l97: #fff4f0;
  --gr-orange-300-l95: #ffece5;
  --gr-orange-400-l91: #ffddd1;
  --gr-orange-500-l83: #ffbea8;
  --gr-orange-600-l67: #ff7547;
  --gr-orange-700-l51: #ff4203; /* Elsevier source --gr */
  --gr-orange-800-l35: #b22d00;
  --gr-orange-900-l27: #8a2300;
  --gr-orange-1000-l23: #751d00;
  --gr-orange-1100-l21: #6b1b00;
  --gr-orange-1200-l19: #611800;

  /* Marigold
    !! To avoid confusion between two colors named Orange we have renamed the original Elsevier Orange to Marigold.
*/
  --gr-marigold-100-l98: #fff9f5;
  --gr-marigold-200-l96: #fff3eb;
  --gr-marigold-300-l94: #ffede0;
  --gr-marigold-400-l90: #ffe2cc;
  --gr-marigold-500-l82: #ffcaa3;
  --gr-marigold-600-l66: #ff9b52;
  --gr-marigold-700-l50: #ff6c00; /* Elsevier source --gr */
  --gr-marigold-800-l34: #ad4900;
  --gr-marigold-900-l26: #853800;
  --gr-marigold-1000-l22: #703000;
  --gr-marigold-1100-l20: #662b00;
  --gr-marigold-1200-l18: #5c2700;

  /* Purple */
  --gr-purple-100-l98: #faf5ff;
  --gr-purple-200-l96: #f5ebff;
  --gr-purple-300-l94: #efe0ff;
  --gr-purple-400-l90: #e5ccff;
  --gr-purple-500-l82: #d1a3ff;
  --gr-purple-600-l66: #a852ff;
  --gr-purple-700-l42: #6b00d6;
  --gr-purple-800-l26: #420085;
  --gr-purple-900-l18: #2e005c;
  --gr-purple-1000-l14: #240047;
  --gr-purple-1100-l12: #1f003d;
  --gr-purple-1200-l10: #190033;

  /* Green */
  --gr-green-100-l98: #f5fff8;
  --gr-green-200-l96: #ebfff0;
  --gr-green-300-l94: #e0ffe9;
  --gr-green-400-l90: #ccffda;
  --gr-green-500-l82: #a3ffbc;
  --gr-green-600-l66: #52ff81;
  --gr-green-700-l42: #00d63b;
  --gr-green-800-l26: #0d9634;
  --gr-green-900-l18: #096724;
  --gr-green-1000-l14: #004714;
  --gr-green-1100-l12: #003d11;
  --gr-green-1200-l10: #00330e;

  /* Yellow */
  --gr-yellow-100-l98: #fffdf5;
  --gr-yellow-200-l97: #fffcf0;
  --gr-yellow-300-l95: #fff9e5;
  --gr-yellow-400-l91: #fff5d1;
  --gr-yellow-500-l83: #ffeca8;
  --gr-yellow-600-l67: #ffdb57;
  --gr-yellow-700-l51: #ffc905;
  --gr-yellow-800-l35: #b28c00;
  --gr-yellow-900-l27: #8a6c00;
  --gr-yellow-1000-l23: #755c00;
  --gr-yellow-1100-l21: #6b5400;
  --gr-yellow-1200-l19: #614c00;

  /* Red */
  --gr-red-100-l98: #fdf6f7;
  --gr-red-200-l96: #ffebeb;
  --gr-red-300-l94: #ffe0e1;
  --gr-red-400-l90: #ffccce;
  --gr-red-500-l82: #ffa3a7;
  --gr-red-600-l66: #ff5259;
  --gr-red-700-l42: #d60009;
  --gr-red-800-l26: #850005;
  --gr-red-900-l18: #5c0004;
  --gr-red-1000-l14: #470003;
  --gr-red-1100-l12v: #3d0002;
  --gr-red-1200-l10: #330002;

  /* Tokens
    !! https://graphene.elsevier.com/#/foundations/tokens/color
  */
  /* !! Text */
  --gr-text-primary: var(--gr-neutral-black-l0);
  --gr-text-secondary: var(--gr-grey-700-l42);
  --gr-text-interaction-idle: var(--gr-blue-700-l42);
  --gr-text-interaction-hover: var(--gr-blue-800-l26);
  --gr-text-interaction-pressed: var(--gr-blue-900-l18);
  --gr-text-disabled: var(--gr-grey-500-l82);
  --gr-text-inverted: var(--gr-neutral-white-l100);
  --gr-text-brand: var(--gr-orange-600-l67);
  --gr-text-error: var(color-red-700-l42);
  --gr-text-error-2: var(--gr-red-900-l18);
  --gr-text-success: var(--gr-green-800-l26);
  --gr-text-success-2: var(--gr-green-900-l18);
  --gr-text-warning-2: var(--gr-yellow-900-l27);
  --gr-text-information-2: var(--gr-petrol-900-l24);
  --gr-text-interaction-idle-inverted: var(--gr-blue-500-l63);
  --gr-text-interaction-hover-inverted: var(--gr-blue-400-l76);
  --gr-text-interaction-pressed-inverted: var(--gr-blue-300-l88);

  /* !! Border */
  --gr-border-idle: var(--gr-grey-600-l62);
  --gr-border-hover: var(--gr-grey-700-l42);
  --gr-border-pressed: var(--gr-grey-800-l26);
  --gr-border-interaction-idle: var(--gr-blue-700-l42);
  --gr-border-interaction-hover: var(--gr-blue-800-l26);
  --gr-border-interaction-pressed: var(--gr-blue-900-l18);
  --gr-border-default-disabled: var(--gr-grey-500-l82);
  --gr-border-error: var(--gr-red-700-l42);
  --gr-border-error-2: var(--gr-red-900-l18);
  --gr-border-success: var(--gr-green-800-l26);
  --gr-border-success-2: var(--gr-green-900-l18);
  --gr-border-inform: var(--gr-petrol-700-l48);
  --gr-border-information-2: var(--gr-petrol-900-l24);
  --gr-border-warning: var(--gr-yellow-800-l35);
  --gr-border-warning-2: var(--gr-yellow-900-l27);
  --gr-border-idle-subtle: var(--gr-grey-400-l90);
  --gr-border-inverted: var(--gr-neutral-white-l100);

  /* !! Surface */
  --gr-surface-sf-0: var(--gr-neutral-white-l100);
  --gr-surface-sf-1: var(--gr-grey-100-l98);
  --gr-surface-sf-2: var(--gr-grey-200-l96);
  --gr-surface-sf-3: var(--gr-grey-300-l94);
  --gr-surface-sf-4: var(--gr-grey-400-l90);
  --gr-surface-sf-5: var(--gr-grey-500-l82);
  --gr-surface-sf-error: var(--gr-red-300-l94);
  --gr-surface-sf-success: var(--gr-green-300-l94);
  --gr-surface-sf-warning: var(--gr-yellow-300-l95);
  --gr-surface-sf-information: var(--gr-petrol-300-l92);
  --gr-surface-sf-interaction-idle: var(--gr-neutral-white-l100);
  --gr-surface-sf-interaction-hover: var(--gr-blue-100-l96);
  --gr-surface-sf-interaction-pressed: var(--gr-blue-200-l92);
  --gr-surface-sf-interaction-idle-inverted: var(--gr-neutral-black-l0);
  --gr-surface-sf-interaction-hover-inverted: var(--gr-blue-1000-l14);
  --gr-surface-sf-interaction-pressed-inverted: var(--gr-blue-900-l18);
  --gr-surface-sf-0-inverted: var(--gr-neutral-black-l0);

  /* !!Shape  */
  --gr-shape-primary: var(--gr-neutral-black-l0);
  --gr-shape-secondary: var(--gr-grey-700-l42);
  --gr-shape-interaction-idle: var(--gr-blue-700-l42);
  --gr-shape-interaction-hover: var(--gr-blue-800-l26);
  --gr-shape-interaction-pressed: var(--gr-blue-900-l18);
  --gr-shape-interaction-idle-inverted: var(--gr-blue-500-l63);
  --gr-shape-interaction-hover-inverted: var(--gr-blue-400-l76);
  --gr-shape-interaction-pressed-inverted: var(--gr-blue-300-l88);
  --gr-shape-error-idle: var(--gr-red-700-l42);
  --gr-shape-error-hover: var(--gr-red-800-l26);
  --gr-shape-error-pressed: var(--gr-red-900-l18);
  --gr-shape-disabled: var(--gr-grey-500-l82);
  --gr-shape-inverted: var(--gr-neutral-white-l100);
  --gr-shape-brand: var(--gr-orange-700-l51);
  --gr-shape-success: var(--gr-green-800-l26);
  --gr-shape-logo-picture: var(--gr-grey-1000-l14);
  --gr-shape-logo-wordmark: var(--gr-orange-700-l51);
  --gr-shape-icon-information: var(--gr-petrol-900-l24);
  --gr-shape-icon-success: var(--gr-green-900-l18);
  --gr-shape-icon-warning: var(--gr-yellow-900-l27);
  --gr-shape-icon-error: var(--gr-red-900-l18);

  /* !! Legacy */
  --gr-legacy-color-action-highlight: #0046ad;
  --gr-legacy-color-action-dimmed: #085781;
  --gr-legacy-color-brand: #ff4203;
  --gr-legacy-color-base: #ffffff;
  --gr-legacy-color-base--inverted: #252c37;
  --gr-legacy-color-base-1: #e0e4eb;
  --gr-legacy-color-base-2: #e7eaef;
  --gr-legacy-color-base-3: #edeff3;
  --gr-legacy-color-base-4: #f3f4f7;
  --gr-legacy-color-base-5: #f9fafb;
  --gr-legacy-color-page-contrast-1: #f3f4f7;
  --gr-legacy-color-page-contrast-2: #e7eaef;
  --gr-legacy-color-page-contrast-3: #dcdcdc;
  --gr-legacy-color-page-contrast-4: #566780;
  --gr-legacy-color-input-base: #ffffff;
  --gr-legacy-color-input-contrast-1: #878787;
  --gr-legacy-color-input-contrast-2: #323232;
  --gr-legacy-color-action-contrast: #f3f4f7;
  --gr-legacy-color-select-contrast-1: #f2f8fa;
  --gr-legacy-color-select-contrast-2: #e4f0f4;
  --gr-legacy-color-interaction-disabled: #919bac;
  --gr-legacy-color-interaction-placeholder: #696969;
  --gr-legacy-color-text-secondary--inverted: #919bac;
  --gr-legacy-color-text-header: #252c37;
  --gr-legacy-color-text-link: #0056d6;
  --gr-legacy-color-error-dimmed: #f4d7d4;
  --gr-legacy-color-warning: #ffd200;
  --gr-legacy-color-warning-dimmed: #fff6c4;
  --gr-legacy-color-highlight: #009ece;
  --gr-legacy-color-highlight-dimmed: #bfe2f3;
  --gr-legacy-color-success-dimmed: #caedc6;
  --gr-legacy-color-info: #009ece;
  --gr-legacy-color-info-dimmed: #e4f5fa;
}
