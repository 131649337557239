/*
!!!!! ALL SPECIFIC CSS PROPERTIES CREATE HERE NEED TO BE DELETED AS THE NEW css-properties.css FILE
!!!!! HANDLES ALL CSS PROPERTIES/VARIABLE MOVE FORWARD

!!!!!!!!!!  ONLY THE @import "pathToComponent-sassFile" SHOULD BE IN THIS FILE`
*/
& {
  /* Page Root */
  --ev-root-background-color: var(--es-color-grey8);
  --ev-root-font-color: var(--es-color-grey0);

  background-color: var(--ev-root-background-color);
  color: var(--ev-root-font-color);

  /* Accordion */
  --ev-accordion-background-color: inherit;
  --ev-accordion-font-color: var(--es-color-grey0);
  @import "./components/Accordion.module.scss";

  /* Avatar */
  @import "./components/Avatar.module.scss";

  /* BottomNavigation */
  @import "./components/BottomNavigation.module.scss";

  /* Box */
  @import "./components/Box.module.scss";

  /* Button */
  --ev-button-transition:
    /**/ background-color var(--es-animation-hover),
    /**/ border-color var(--es-animation-hover), /**/ color var(--es-animation-hover);
  --ev-button-border-width: 2px;
  --ev-button-border-style: solid;

  --ev-button-primary-color-background: var(--es-color-elsevier-blue);
  --ev-button-primary-color-font: var(--es-color-grey0);
  --ev-button-primary-color-background-hover: var(--es-color-grey0);
  --ev-button-primary-color-font-hover: var(--es-color-grey9);
  --ev-button-primary-color-border-hover: var(--es-color-elsevier-orange);

  --ev-button-secondary-color-background: var(--es-color-grey0);
  --ev-button-secondary-color-background-hover: var(--es-color-grey0);
  --ev-button-secondary-color-border: var(--es-color-elsevier-blue);
  --ev-button-secondary-color-border-hover: var(--es-color-elsevier-orange);
  --ev-button-secondary-color-font: var(--es-color-grey9);
  --ev-button-secondary-color-font-hover: var(--es-color-grey9);

  --ev-button-text-color-background: transparent;
  --ev-button-text-color-background-hover: transparent;
  --ev-button-text-color-border: transparent;
  --ev-button-text-color-border-hover: transparent transparent var(--es-color-elsevier-orange) transparent;
  --ev-button-text-color-font: var(--es-color-grey0);
  --ev-button-text-color-font-hover: var(--es-color-grey0);
  @import "./components/Button.module.scss";

  /* Chip */
  @import "./components/Chip.module.scss";

  /* CSS Grid */
  @import "./components/Grid.module.scss";

  /* CSS Grid */
  @import "./components/CSSGrid.module.scss";

  /* Divider */
  @import "./components/Divider.module.scss";

  /* Input */
  @import "./components/Input.module.scss";

  /* Links */
  @import "./components/Link.module.scss";

  /* Ordered and UnOrdered Lists */
  @import "./components/List.module.scss";

  /* Modal */
  @import "./components/Modal.module.scss";

  /* Popper */
  @import "./components/Popper.module.scss";

  /* Select Form Input */
  @import "./components/Select.module.scss";

  /* SkipLink */
  @import "./components/SkipLink.module.scss";

  /* Switch Grid */
  @import "./components/Switch.module.scss";

  /* Tabs */
  @import "./components/Tabs.module.scss";

  /* Tables */
  @import "./components/Table.module.scss";

  /* Typography */
  @import "./components/Typography.module.scss";

  /* Autosuggest */
  @import "./components/Autosuggest.module.scss";

  /* Tag */
  @import "./components/Tag.module.scss";
}
