.options {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;

  & > div {
    display: flex;
    align-items: center;
    gap: 32px;
  }
}

.button {
  & > strong {
    padding-right: 5px;
  }

  > svg {
    height: 16px;
    margin: 0 5px 0 0;
    width: 16px;
  }
}
