.bar {
  --bar-height: 40px;
  --bar-second-color: rgba(255, 255, 255, 0.3) !important;
  --bar-gap: 1.5rem;
  --color: #252c37;

  padding: 0;

  &__drawer_button {
    float: right;
  }

  [class~="MuiNativeSelect-select"],
  [class~="search-input-row"] > input {
    &:focus-visible {
      outline: 2px solid rgb(var(--sp-color-blue0-rgb)) !important;
      outline-offset: 1px !important;
      border-radius: var(--es-font-size-8) !important;
    }
  }

  [class~="search-input-row"] > input:user-invalid {
    &:focus-visible {
      outline: 2px solid red !important;
      outline-offset: 1px !important;
      border-radius: var(--es-font-size-8) !important;
    }
  }
}

.fields {
  &[class~="ev-search-fields-container"] {
    min-width: 100%;
    margin-block: var(--es-font-size-40);
    padding: 0;
    color: black;

    > div {
      padding-block: 0 !important;
    }
  }

  &__boolean {
    width: 98px;
    float: right;
  }
}
