& [class~="es-input"]{

  &:focus-within {
    outline: 2px solid var(--es-color-a11y-orange);
    outline-offset: 1px;
  }

  & * {
    border-radius: 0;
  }

  label {
    font-style: italic;
    font-weight: 400;
    font-size: 16px;
    color: #737373;
    line-height: 1rem;
    padding-bottom: 5px;
  }

  input {
    font-family: var(--es-font-sans-serif);
    outline: none;
    padding: 0 1rem;
    height: 48px;
    line-height: 48px;

    &::placeholder {
      opacity: 1;
      color: #737373;
      font-size: var(--es-font-size-14);
    }
/*
  TODO SPECIFIC FOR CSP - MUI COMPONENT USES FIELDSET FOR BORDER [ NEED TO FIX AND USE OUTLINE ON INPUT TEXT FIELD AND NOT FIELDSET ]
*/
    &[type=text]:focus + fieldset,
    &[type=text]:hover + fieldset {
      border-width: 2px;
    }

    // &[type=text]:focus + fieldset {
    //   border-color: var(--es-color-a11y-orange);
    // }
  }
/*
  TODO SPECIFIC FOR CSP - MUI COMPONENT USES FIELDSET FOR BORDER [ NEED TO FIX AND USE OUTLINE ON INPUT TEXT FIELD AND NOT FIELDSET ]
*/
  fieldset {
    border: 1px solid #2E2E2E;
    border-bottom-width: 2px;
  }
}