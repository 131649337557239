// aka pills

a[class~="es-tag"],
button[class~="es-tag"] {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: unset;
  padding: unset;
  font-weight: 400;

  // !  DEFAULT STYLES
  &[class~="es-tag__primary"],
  &[class~="es-tag__primary"][class~="disabled"] {
    background-color: var(--es-color--white);
    border: var(--es-fs--01) solid var(--gr-border-idle);
    border-radius: var(--es-br--xxxl);
    font-size: var(--es-fs--14);
    line-height: var(--es-lh-14);
    height: var(--es-mp--28);
    padding-block: var(--es-fs--04);
    padding-inline: var(--es-mp--12);
    color: var(--es-color--black);

    &:disabled {
      background-color: var(--es-color--grey-600);
      border-color: var(--es-color--grey-600);
      color: var(--es-color--white);
      cursor: not-allowed;
    }

    &:focus-visible {
      outline: var(--es-fs--02) solid var(--es-color--blue-900);
      outline-offset: var(--es-fs--01);
    }

    &:hover:not(:disabled) {
      border-color: var(--es-color--blue-900);
    }
  }
}
