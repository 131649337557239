:root {
  -webkit-font-smoothing: antialiased;
  /* FONT FAMILIES */
  /* Elsevier Sans - Default Body Font Familiy */
  --es-font-sans-serif:
    "ElsevierSans",
    Arial,
    Helvetica,
    sans-serif;

  --es-font-sans-serif-bold:
    "ElsevierSans-Bold",
    sans-serif;

  --es-font-sans-serif-italic:
    "ElsevierSans-Italic",
    sans-serif;

  --es-font-sans-serif-bold-italic:
    "ElsevierSans-Bold-Italic",
    sans-serif;

  /* Elsevier Display Light -- Default font family for heading levels */
  --es-font-display-light:
    "ElsevierDisplay-Light",
    "ElsevierDisplay",
    sans-serif;

  --es-font-display-light-italic:
    "ElsevierDisplay-Light-Italic",
    "ElsevierDisplay-Italic";

  /* Elsevier Gulliver -- font family used in some areas that have alerts etc */

  --es-font-gulliver:
  "ElsevierGulliver",
  Arial,
  Helvetica,
  sans-serif;

  --es-font-gulliver-bold:
  "ElsevierGulliver-Bold",
  Arial,
  Helvetica,
  sans-serif;

  --es-font-gulliver:
  "ElsevierGulliver",
  Arial,
  Helvetica,
  sans-serif;

  /* Nexus Serif - The old default font family used to be used - Keeping for fall back
     !! REVISIT and possibly delete the font family and property at later date
  */

  --es-font-serif:
    "NexusSerif",
    Georgia,
    "Times New Roman",
    serif;

  --es-font-display:
    "ElsevierDisplay",
    "ElsevierSans",
    Arial,
    Helvetica,
    sans-serif;

  --es-font-monospace:
    Monaco,
    Consolas,
    Andale Mono,
    monospace;

    /*
  ! ALL ALLOWED FONT SIZE BASED ON 16px BASE SET
  */
  --es-font-size-4: 0.25rem;
  --es-font-size-8: 0.5rem;
  --es-font-size-12: 0.75rem;
  --es-font-size-14: 0.875rem;
  --es-font-size-16: 1rem;
  --es-font-size-18: 1.125rem;
  --es-font-size-20: 1.25rem;
  --es-font-size-22: 1.375rem;
  --es-font-size-24: 1.5rem;
  --es-font-size-26: 1.625rem;
  --es-font-size-28: 1.75rem;
  --es-font-size-30: 1.875rem;
  --es-font-size-32: 2rem;
  --es-font-size-34: 2.125rem;
  --es-font-size-36: 2.25rem;
  --es-font-size-38: 2.375rem;
  --es-font-size-40: 2.5rem;
  --es-font-size-42: 2.625rem;
  --es-font-size-44: 2.75rem;
  --es-font-size-46: 2.875rem;
  --es-font-size-48: 3rem;
  --es-font-size-56: 3.5rem;
  --es-font-size-64: 4rem;

  --es-line-height: line-height: calc(1em + 8px);

  --es-font-weight-normal: 400;
  --es-font-weight-heading: 500;
  --es-font-weight-bold: 700;
}

/*
  END CSS Custom
*/
html,
body,
#ev-body,
.es-html-body {
  font-family: var(--es-font-sans-serif);
  font-size: var(--es-font-size-16);
  font-style: normal;
  font-weight: normal;
  font-variant-numeric: lining-nums proportional-nums;
  line-height: var(--es-line-height);
  margin: 0;
  overflow: visible;
  padding: 0;
}

/*
**  Font Family Helper Classes
**  Can use these classes on HTML5 elements to override the defaults set if called for
*/
.es-font-nexus-sans,
.es-font-elsevier-sans {
  font-family: var(--es-font-sans-serif);
}

.es-font-elsevier-sans-bold,
strong,
b {
  font-family: var(--es-font-sans-serif-bold);
  font-weight: var(--es-font-weight-bold);
}

.es-font-elsevier-sans-italic,
em,
i {
  font-family: var(--es-font-sans-serif-italic);
  font-style: italic;
}

.es-font-elsevier-sans-bold-italic,
strong > em,
strong > i,
b > i,
em > strong,
i > strong,
i > b {
  font-family: var(--es-font-sans-serif-bold-italic);
  font-style: italic;
  font-weight: var(--es-font-weight-bold);
}

.es-font-elsevier-display {
  font-family: var(--es-font-display);
}

.es-font-elsevier-display-bold-condensed {
  font-family: var(--es-font-display);
  font-weight: var(--es-font-weight-bold);
}

.es-font-elsevier-display-regular-condensed {
  font-family: var(--es-font-display);
  font-weight: var(--es-font-weight-normal);
}

.es-font-elsevier-display-light {
  font-family: var(--es-font-display-light);
}

.es-font-elsevier-display-light-italic {
  font-family: var(--es-font-display-light-italic);
  font-style: italic;
}

.es-font-gulliver {
  font-family: var(--es-font-gulliver);
  font-weight: normal;
}

h1,
h2,
h3,
h4,
h5,
h6,
.es-heading-h1,
.es-heading-h2,
.es-heading-h3,
.es-heading-h4,
.es-heading-h5,
.es-heading-h6,
.es-heading-h5-caps,
.es-heading-h6-caps,
/* ! Unfortunately - the .es-para class is being inserted and will override heading font styles */
h1.es-para,
h2.es-para,
h3.es-para,
h4.es-para,
h5.es-para,
h6.es-para {
/* ! End specificity */
  font-family: var(--es-font-display-light);
  font-weight: var(--es-font-weight-heading);
}

h2,
h3,
h4,
h5,
h6,
.es-heading-h2,
.es-heading-h3,
.es-heading-h4,
.es-heading-h5,
.es-heading-h6,
.es-heading-h5-caps,
.es-heading-h6-caps {
  margin: calc(2em - 16px) 0 calc(1em - 8px);
}

h1 + h2,
h2 + h3,
h3 + h4,
h4 + h5,
h5 + h6,
.es-heading-h1 + .es-heading-h2,
.es-heading-h2 + .es-heading-h3,
.es-heading-h3 + .es-heading-h4,
.es-heading-h4 + .es-heading-h5,
.es-heading-h5 + .es-heading-h6 {
  margin-top: calc(1em - 8px);
}

h1,
h1.es-para,
.es-heading-h1 {
  font-size: var(--es-font-size-32);
}

h2,
h2.es-para,
.es-heading-h2 {
  font-size: var(--es-font-size-24);
}

h3,
h4,
h5,
h6,
h3.es-para,
h4.es-para,
h5.es-para,
h6.es-para,
.es-heading-h3,
.es-heading-h4,
.es-heading-h5,
.es-heading-h5-caps,
.es-heading-h6,
.es-intro {
  font-size: var(--es-font-size-16);
}

.es-meta {
  font-size: var(--es-font-size-14);
}

.es-batch {
  font-size: var(--es-font-size-12);
}

.es-heading-h5-caps,
.es-heading-h6-caps {
  background-image:
    /**/ linear-gradient(var(--es-color-theme2), var(--es-color-theme2));
  background-position: bottom left;
  background-repeat: no-repeat;
  background-size: 32px 4px;
  font-weight: 700;
  padding-bottom: 0.5em;
  text-transform: uppercase;
}

p,
p.es-para,
input[type=text],
select {
  font-family: var(--es-ff--primary);
  font-size: var(--es-fs--16);
  line-height: var(--es-lh--16);
}

p {
  margin-block-start: var(--es-fs--16);
  margin-block-end: var(--es-fs--24);
}

p.es-para {
  margin: unset;
  padding: unset;
}

table,
table tr,
table tr td {
  font-variant-numeric: lining-nums tabular-nums;
}

.__bold {
  font-weight: 700;
}
/*  Form Overrides  */

/*  Margin Overrides  */
.ev-margin__all-none {
  margin: 0;
}
.ev-margin__top-none {
  margin-top: 0;
}
.ev-margin__right-none {
  margin-right: 0;
}
.ev-margin__bottom-none {
  margin-bottom: 0;
}
.ev-margin__left-none {
  margin-left: 0;
}
.ev-margin__all-1 {
  margin: 1rem;
}
.ev-margin__top-1 {
  margin-top: 1rem;
}
.ev-margin__right-1 {
  margin-right: 1rem;
}
.ev-margin__bottom-1 {
  margin-bottom: 1rem;
}
.ev-margin__left-1 {
  margin-left: 1rem;
}
.ev-margin__top-bottom-1 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.ev-margin__right-left-1 {
  margin-right: 1rem;
  margin-left: 1rem;
}
/*
  PADDING OVERRIDES
*/
.ev-padding__all-none {
  padding: 0;
}
.ev-padding__top-none {
  padding-top: 0;
}
.ev-padding__right-none {
  padding-right: 0;
}
.ev-padding__bottom-none {
  padding-bottom: 0;
}
.ev-padding__left-none {
  padding-left: 0;
}
.ev-padding__all-1 {
  padding: 1rem;
}
.ev-padding__top-1 {
  padding-top: 1rem;
}
.ev-padding__right-1 {
  padding-right: 1rem;
}
.ev-padding__bottom-1 {
  padding-bottom: 1rem;
}
.ev-padding__left-1 {
  padding-left: 1rem;
}
.ev-padding__top-bottom-1 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.ev-padding__right-left-1 {
  padding-right: 1rem;
  padding-left: 1rem;
}

/*
BORDER OVERRIDES
*/
.ev-border__bottom-grey {
  border-bottom: 1px solid var(--es-color-grey9);
}

/*
LIST STYLES
*/
.es-list__no-bullet {
  list-style-type: none;
  padding: 0;
}

/* SCREEN READER ONLY:   text or components with this class will be hidden from browser screen
                      but is still in the DOM so screen reader assistive technology will
                      read this out to the screen reader assistive technology user. */

.sr-only {
  position: absolute;
  left: -10000px;
  width: 1px;
  height: 1px;
  top: auto;
  overflow: hidden;
}

.kb-enabled:focus {
  position: relative;
  left: unset;
  width: auto;
  height: auto;
  overflow: unset;
}

/*
  MUI COMPONENTS OVERRIDES
*/

[class ~= "MuiTypography-root"],
[class ~= "MuiButtonBase-root"] {
  font-family: var(--es-font-sans-serif);
}

/*
  Media Queries
*/
@media (min-width: 576px) {
  :root {
    --es-font-size-24: 24px;
    --es-font-size-32: 32px;
    --es-font-size-40: 40px;
    --es-font-size-48: 40px;
    --es-font-size-56: 40px;
    --es-font-size-64: 40px;
  }
}
@media (min-width: 768px) {
  :root {
    --es-font-size-20: 20px;
    --es-font-size-48: 48px;
    --es-font-size-56: 48px;
    --es-font-size-64: 48px;
  }
}
@media (min-width: 992px) {
  :root {
    --es-font-size-56: 56px;
    --es-font-size-64: 56px;
  }
}
@media (min-width: 1200px) {
  :root {
    --es-font-size-64: 64px;
  }
}