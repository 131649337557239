.EngVillageTheme {
  /* *** Light theme *** */
  &[class~="light-theme"] {
    @import "./themes/EngVillageThemeLight.module.scss";
  }

  /* *** Dark Theme *** */
  &[class~="dark-theme"] {
    @import "./themes/EngVillageThemeDark.module.scss";
  }

/*
!!!!!! WE NEED TO DELET ALL CSS EXCEPT FOR MEDIA QUERIES
!!!!!! BELOW THIS LINE!
*/
  /* Don't show focus ring on elements that are */
  /* removed from the default navigation flow */
  [tabindex="-1"]:focus {
    outline-style: 0;
  }
  /* *** Focus ring *** */

  :focus-visible {
    outline-color: var(--es-color--blue-1000);
    border-radius: var(--es-fs--08);
    outline-offset: 2px;
    outline-style: solid;
    outline-width: 2px;
  }

  /* Disable Firefox specific focus ring, we got our own. */
  ::-moz-focus-inner {
    border: 0;
    outline: 0;
  }
}

/*
!!  SAVE SEARCH HISTORY - SHADOW DOM
*/
privacy-center.hydrated {
  font-family: ElsevierSans, system-ui, sans-serif !important;
}

// Media Queries -

// (XS)
@media screen and (max-width: 599px) {
  .EngVillageTheme {
    main {
      border-top: 2px solid var(--es-color-elsevier-green);
      margin-inline: auto;
      min-height: 100svh;
      min-width: 1024px;
    }
  }
}
// (S) Mobile
@media screen and (min-width: 600px) and (max-width: 899px) {
  .EngVillageTheme {
    main {
      border-top: 2px solid var(--es-color-elsevier-green);
      margin-inline: auto;
      min-height: 100svh;
      min-width: 1024px;
    }
  }
}

// (M) Tablet
@media screen and (min-width: 900px) and (max-width: 1199px) {
  .EngVillageTheme {
    main {
      border-top: 2px solid var(--es-color-elsevier-green);
      margin-inline: auto;
      min-height: 100svh;
      min-width: 1024px;
    }
  }
}

// (L) Desktop/Laptop
@media screen and (min-width: 1200px) and (max-width: 1535px) {
  .EngVillageTheme {
    main {
      border-top: 2px solid var(--es-color-elsevier-green);
      margin-inline: auto;
      min-height: 75svh;
      min-width: 1024px;
    }
  }
}

// (XL) UltraWide
@media screen and (min-width: 1536px) {
  .EngVillageTheme {
    main {
      border-top: 2px solid var(--es-color-elsevier-green);
      margin-inline: auto;
      min-height: 75svh;
      min-width: 1024px;
    }
  }
}