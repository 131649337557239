#ev__copyright {
  align-items: start;
  border-top: 2px solid var(--es-color--orange-100);
  grid-template-columns: auto 1fr auto;
  gap: 2em;
  font-size: var(--es-fs--14);
  margin-block: 0;
  padding: var(--es-fs--32);

  img {
    margin-top: var(--es-mp--04);
  }

  .footer__links {
    list-style-type: none;
    display: flex;
    align-items: center;
    gap: var(--es-fs--24);
    margin: 0;
    padding: 0;

    [class~="es-link__icon--left"],
    [class~="es-link__icon--right"] {
      // 7px height and width per figma
      width: auto;

      [class~="MuiSvgIcon-root"] {
        width: var(--es-fs--08);
      }
    }
  }

  .ev__copyright-statement {
    font-size: var(--es-fs--14);
    margin-block: var(--es-fs--28) 0;
    max-width: 75%;
    line-height: var(--es-lh--14);

    [class~="MuiSvgIcon-root"] {
      width: var(--es-fs--08);
      margin-left: var(--es-fs--04);
    }
  }
}

.ot-sdk-show-settings {
  //Have to override the color styling coming back from the cookie compliance script
  color: var(--es-color--black) !important;
  background-color: transparent !important;
  border: none !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  font-size: var(--es-font-size-14) !important;
  height: 0 !important;
  font-weight: var(--es-fw--heavy) !important;
}
.ot-sdk-show-settings:hover {
  color: var(--es-color--blue-900) !important;
}
