/*  Global CSS Grid Display  */
[class~="ev-grid__display-grid"] {
  display: grid;
}

[class~="ev-grid__display-grid-inline"] {
  display: inline-grid;
}

[class~="grid-container-border"] {
  border-color: rgb(192, 192, 192);
  border-image: initial;
  border-style: groove;
  border-width: 2px;
  min-inline-size: min-content;
}
/*
    TODO:
    - column-gap
    - row-gap
    - gap
    - grid-auto-column -- specifies default column size
    - grid-auto-row -- specifies default row size
    - grid-template-columns
    - grid-template-rows
    - justify-content
    - align-content
    - grid-column -- shorthand for grid-column-start and grid-column-end (used if you want a particular column size to be multiple     columns etc.
            ex: grid-column: 1/5 (starts at line 1 and ends at line 5 so this will expand 4 columns
            OR grid-column: 1 / span 4 (starts at line 1 and will span 4 columns)
    - the above for rows -- grid-row

*/
