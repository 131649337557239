div[class~="es-accordion"][class~="MuiAccordion-root"] {
  background-color: #fff;
  box-shadow: none;
  color: inherit;

  div[class~="es-accordion-summary"] {
    color: inherit;
    padding: 0;

  }

  div[class~="es-accordion-details"] {
    color: inherit;
    // padding: var(--ev-accordion-details-padding);
  }

  div[class~="MuiAccordionSummary-expandIconWrapper"] {
    color: var(--es-color-elsevier-blue);
  }
}

div[class~="MuiAccordion-root"]:before {
  display: none;
}

// MuiAccordionSummary-expandIconWrapper Mui-expanded css-yw020d-MuiAccordionSummary-expandIconWrapper
// MuiAccordionSummary-expandIconWrapper css-yw020d-MuiAccordionSummary-expandIconWrapper

// MuiSvgIcon-root MuiSvgIcon-fontSizeInherit css-1vooibu-MuiSvgIcon-root