div[class~="es--box"] {
}
div[class~="es--labelvalue-box"] {
  margin-bottom: 12px;
  display: flex;
  &[class*="ev__single"] {
    flex-direction: row;
    & [class*="ev__label"]:after {
      content: ":\00a0";
    }
  }
  &[class*="ev__multi"] {
    flex-direction: column;
  }
  & [class*="ev__label"] {
    font-weight: 700;
  }
}
