/* Styling for MUI popper as "dropdown" menus */
[class~="es-menu-popper"],
[class~="es-popper"] {
  box-shadow: none;
  top: 1rem;
  list-style: none;
  z-index: 2;
  text-align: left;
  border: none;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;

  & [class~="__close"] {
    position: absolute;
    right: var(--es-fs--16);
    top: var(--es-fs--16);
  }

  & [class~="__close"] svg:hover {
    fill: var(--es-color-elsevier-orange);
  }

  & [class~="__badge"] > svg {
    height: var(--es-fs--20);
    width: var(--es-fs--20);
    line-height: var(--es-lh--20);
  }

  //  STYLING FOR THE MENU EXPAND/COLLAPSE ICON
  & [class ~= "__icon-wrapper"] {
    display: inline-flex;
    align-items: center;
    padding-left: var(--es-fs--06);
    & > svg {
      height: unset;
      width: unset;
      height: var(--es-fs--20);
      max-width: var(--es-fs--20);
      line-height: var(--es-lh--20);
    }
  }

  & [data-popper-placement~="bottom-start"] {
    [class~="__content-wrap"] {
      box-shadow: 7px -9px 0px -7px var(--es-color-elsevier-orange);
    }
    [class~="--pointer"] {
      background-position: left bottom;
      border-left: 1px solid var(--es-color-grey5);
      left: 0;
      background-image: linear-gradient(
        225deg,
        transparent 11px,
        var(--es-color-elsevier-orange) 11px,
        var(--es-color-elsevier-orange) 13px,
        rgba(var(--es-color-grey2-rgb), 0.85) 13px
      );
    }
  }

  & [data-popper-placement~="bottom-end"] {
    [class~="__content-wrap"] {
      box-shadow: -7px -9px 0px -7px var(--es-color-elsevier-orange);
    }
    [class~="--pointer"] {
      background-position: right bottom;
      border-right: 1px solid var(--es-color-grey5);
      right: 0;
      background-image: linear-gradient(
        -225deg,
        transparent 11px,
        var(--es-color-elsevier-orange) 11px,
        var(--es-color-elsevier-orange) 13px,
        rgba(var(--es-color-grey2-rgb), 0.85) 13px
      );
    }
  }

  & [class~="--pointer"] {
    position: absolute;
    top: -17px;
    background: left bottom / 16px 16px no-repeat;
    display: block;
    height: 17px;
    width: 17px;
  }

  & [class*="__content-wrap"] {
    background-color: rgba(var(--es-color-grey2-rgb), 0.85);
    border: 1px solid var(--es-color-grey5);
    border-top-color: transparent;
    box-sizing: border-box;
    max-width: calc(100vw);
    padding: 16px;

    & [class*="__content"] {
      flex-direction: column;
      display: flex;
      align-items: flex-start;
      background-color: #fff;
      overflow: hidden;
      padding: 1rem;
    }
  }
}

[class~="es-popper"] [class*="__content-wrap"] {
  border-top: 2px solid var(--es-color-elsevier-orange);
}
