[class~="es-switch"] {
  border: var(--es-fs--02) solid var(--es-color--grey-700);
  border-radius: var(--es-br--xxl);
  height: var(--es-fs--20);
  width: var(--es-fs--40);
  padding: 0;
  outline: var(--es-fs--02) solid var(--es-color--white);
  outline-offset: var(--es-fs--01);

  [class~="MuiTouchRipple-root"] {
    display: none;
  }

  //! NOT CHECKED STATE
  [class~="MuiSwitch-switchBase"] {
    color: var(--es-color--grey-700);
    transition: 500ms;
    transform: translate(-4px, -5px);

    &:hover {
      background-color: transparent;
    }

    [class~="MuiSwitch-thumb"] {
      height: var(--es-fs--08);
      width: var(--es-fs--08);
    }

    & +[class~="MuiSwitch-track"] {
      background-color: var(--es-color--white);
      width: 100%;
      height: 100%;
      opacity: 1;
    }
  }

  &:focus-within {
    outline-color: var(--es-color--blue-900);
  }

  &:hover {
    border-color: var(--es-color--black);
  }

  //! CHECKED STATE
  [class~="Mui-checked"] {
    color: var(--es-color--white);
    transform: translate(12px, -7px);

    [class~="MuiSwitch-thumb"] {
      height: var(--es-fs--12);
      width: var(--es-fs--12);
    }

    & +[class~="MuiSwitch-track"] {
      background-color: var(--es-bg--primary);
      border: var(--es-fs--02) solid var(--es-color--blue-900);
      opacity: 1;
    }
  }

  &:has([class~="Mui-checked"]) {
    border-color: var(--es-color--blue-900);
    outline-offset: 0;
  }
}