[class~="grid__container"] {
    display: grid;
    grid-auto-columns: auto;
    grid-gap: 1rem;
    grid-auto-flow: row;

    /**
    * *
    * * COLUMN SIZES (Per RELX Design System) Based off 12 column template.  Layouts are 1 column (spans all 12 column), 2 column
    * * 1 column - spans all 12 columns
    * * 2 column - 1 column spans 6 columns
    * * 3 column - 1 column spans 4 columns
    * * 4 column - 1 column spans 3 columns
    * * 6 column - 1 column spans 2 columns
    * *
    * * If any additional column or configuration is needed, then create col-XX className specific to your component .scss file
    **/

    &[class*="col-1"] {
        grid-template-columns: 1fr;
    }

    &[class*="col-2"] {
        grid-template-columns: repeat(2, 6fr);
    }

    &[class*="col-3"] {
        grid-template-columns: repeat(3, 4fr);
    }

    &[class*="col-4"] {
        grid-template-columns: repeat(4, 3fr);
    }

    &[class*="col-5"] {
        grid-template-columns: repeat(5, 1fr);
    }

    &[class*="col-6"] {
        grid-template-columns: repeat(6, 2fr);
    }

    [class~="grid__container-item"] {
        font-family: inherit;

        // Individual horizontal alignment
        &[class~="grid-start"] {
            justify-self: start;
        }

        &[class~="grid-center"] {
            justify-self: center;
        }

        &[class~="grid-end"] {
            justify-self: end;
        }

        // Individual vertical alignment
        &[class~="grid-top"] {
            align-self: start;
        }

        &[class~="grid-middle"] {
            align-self: center;
        }

        &[class~="grid-bottom"] {
            align-self: end;
        }
    }
}