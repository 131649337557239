.es-dialog {
  & [class*="MuiPaper-root"] {
    width: 40rem;
    padding: 1rem;

    & > * {
      padding: inherit;
    }
  }
}
