[class~="MuiTabs-root"] [class~="es-tabs"] {
  border: unset;
  display: unset;
}

[class~="MuiTabs-scroller"] [class~="MuiTabs-fixed"] {
  display: block;
  margin-bottom: 1rem !important;
}

[class~="els-tab-list__items"] {
  /*
  * THE MAIN CONTAINER FOR THE TAB LIST ITEMS
  * NEXT CONTAINER IS FOR SETTING SCROLLING OR FIXED AKA MuiTabs-scroller or MuiTabs-fixed
  */
  border: unset;
  width: 100%;

  & [class~="MuiTabs-indicator"] {
    display: none;
  }

  & [class~="els-tab-list__item"] {
    border-bottom: 2px solid var(--es-color-grey4);
    font-size: var(--es-font-size-20);
    padding: 0;
    width: 100%;

    &:focus-visible {
      outline: none;
    }

    &:hover,
    &:focus,
    &[aria-selected="true"] {
      border-bottom: 2px solid var(--es-color-a11y-orange);
      color: var(--es-color-grey9);
    }

    &:hover {
      color: var(--es-color-a11y-orange);
    }

    &:hover,
    &:focus,
    &[aria-selected="true"] {
      border-bottom: 2px solid #000;
      color: var(--es-color-a11y-orange);
    }
  }

  /*   &[class~="Mui-selected"],
  &:hover,
  &:focus {
    border: unset;
    border-bottom: 2px solid #000;
    color: var(--es-color-a11y-orange);
    outline: unset;
  } */

  /* &:focus {
    border: 2px solid var(--es-color-a11y-orange);
    border-radius: 2px;
  } */
}
